import React, { useState } from 'react';
import { 
  auth, 
  signInWithEmailAndPassword, 
  sendPasswordResetEmail,
  createUserWithEmailAndPassword
} from '../../firebase';
import { doc, setDoc } from "firebase/firestore";
import { db } from '../../firebase';
import './Auth.css';

const Auth = ({ onLogin, onRegisterSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [message, setMessage] = useState(''); // Добавляем состояние для сообщений

  const handleLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      onLogin(userCredential.user);
    } catch (error) {
      setMessage('Ошибка входа: ' + error.message);
    }
  };
  const generateDefaultAvatar = (name) => {
    const formattedName = name.replace(/\s+/g, '+'); // Заменяем пробелы на "+"
    return `https://ui-avatars.com/api/?name=${formattedName}&background=random&size=200`;
  };
  const handleRegister = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Генерация аватара по умолчанию
      const defaultName = "Новичок";
      const defaultAvatar = generateDefaultAvatar(defaultName);
  
      // Создание документа пользователя в Firestore
      const userDocRef = doc(db, "users", user.uid);
      await setDoc(userDocRef, {
        email: user.email,
        name: defaultName, // Имя по умолчанию
        position: "Без работы", // Должность по умолчанию
        avatar: defaultAvatar, // Аватар по умолчанию
        balance: 300, // Начальный баланс
      });
  
      setMessage('Регистрация успешна. Теперь вы можете войти.');
      onRegisterSuccess(user);
    } catch (error) {
      setMessage('Ошибка регистрации: ' + error.message);
    }
  };
  

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Ссылка для сброса пароля отправлена на ваш email.');
      setIsResettingPassword(false);
    } catch (error) {
      setMessage('Ошибка при отправке ссылки для сброса пароля: ' + error.message);
    }
  };

  return (
    <div className="auth-container">
      {isResettingPassword ? (
        <div className="auth-form">
          <h2>Восстановление пароля</h2>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="auth-input"
          />
          <button onClick={handlePasswordReset} className="auth-button">Отправить ссылку для сброса пароля</button>
          <button onClick={() => setIsResettingPassword(false)} className="auth-button">Отмена</button>
        </div>
      ) : (
        <div className="auth-form">
          <h2>Вход</h2>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="auth-input"
          />
          <input
            type="password"
            placeholder="Пароль"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="auth-input"
          />
          <button onClick={handleLogin} className="auth-button">Войти</button>
          <button onClick={handleRegister} className="auth-button">Зарегистрироваться</button>
          <button onClick={() => setIsResettingPassword(true)} className="auth-button">Забыли пароль?</button>
        </div>
      )}
      {message && <div className="auth-message">{message}</div>}
    </div>
  );
};

export default Auth;
