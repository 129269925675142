// FilterBar.js

import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './FilterBar.css';

const categories = [
  'all', 'Салаты', 'Закуски', 'Горячие закуски', 'Рыба', 'Мясо', 'Паста', 'Фуршет', 'Банкет', 'Обеды', 'Напитки', 'Спец предложение', 'Завтраки', 'Заготовки'
];

const divideIntoRows = (items, numberOfRows) => {
  const rows = Array.from({ length: numberOfRows }, () => []);
  items.forEach((item, index) => {
    rows[index % numberOfRows].push(item);
  });
  return rows;
};

const FilterBar = ({ onFilter, showSortByNew }) => {
  const [searchText, setSearchText] = useState('');
  const [calories, setCalories] = useState(5000);
  const [selectedFilters, setSelectedFilters] = useState({
    category: 'all',
    author: '',
    sortBy: 'new',
    calories: 5000,
  });

  const totalRows = 1;
  const rows = divideIntoRows(categories, totalRows);
  const sliderRef = useRef(null);

  const handleScroll = (direction) => {
    const slider = sliderRef.current;
    const scrollAmount = 200;
    const maxScroll = slider.scrollWidth - slider.clientWidth;

    let newPosition = slider.scrollLeft + (direction === 'right' ? scrollAmount : -scrollAmount);

    if (newPosition >= maxScroll) {
      newPosition = 0;
    } else if (newPosition < 0) {
      newPosition = maxScroll;
    }

    slider.scrollTo({
      left: newPosition,
      behavior: 'smooth',
    });
  };

  // Обновляем фильтры при изменении категории
  const handleCategoryChange = (category) => {
    const newFilters = { ...selectedFilters, category };
    setSelectedFilters(newFilters);
    onFilter(newFilters);
  };

  // Обновляем фильтры при изменении текста поиска
  const handleInputChange = (e) => {
    const newSearchText = e.target.value;
    setSearchText(newSearchText);
    const newFilters = { ...selectedFilters, author: newSearchText };
    setSelectedFilters(newFilters);
    onFilter(newFilters);
  };

  // Обновляем фильтры при изменении калорий
  const handleCaloriesChange = (value) => {
    const numericValue = Number(value);
    const sanitizedValue = isNaN(numericValue) ? 0 : numericValue;
    const newFilters = { ...selectedFilters, calories: sanitizedValue };
    setCalories(sanitizedValue);
    setSelectedFilters(newFilters);
    onFilter(newFilters);
  };

  // Обновляем фильтры при изменении сортировки на "новизне"
  const handleSortByNew = () => {
    const newFilters = { ...selectedFilters, sortBy: 'new' };
    setSelectedFilters(newFilters);
    onFilter(newFilters);
  };

  // Инициализация фильтров при загрузке компонента
  useEffect(() => {
    onFilter(selectedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="filter-container">
      <div className="category-filter-slider-wrapper">
    
        <div className="category-filter-slider" ref={sliderRef}>
          {rows.map((row, rowIndex) => (
            <div key={rowIndex} className="category-filter-row">
              {row.map((category) => (
                <button
                  key={category}
                  className={`category-filter-item ${selectedFilters.category === category ? 'active' : ''}`}
                  onClick={() => handleCategoryChange(category)}
                >
                  {category}
                </button>
              ))}
            </div>
          ))}
        </div>
 
      </div>

      <div className="search-bar">
        <input
          type="text"
          className="search-input"
          placeholder="Поиск по названию или автору"
          value={searchText}
          onChange={handleInputChange}
        />
        {/* Кнопка поиска удалена, поскольку фильтрация происходит мгновенно */}
      </div>

      <div className="filter-options">
        <div className="calories-slider-container">
          <span>Калории:</span>
          <input
            type="range"
            min="0"
            max="5000"
            value={calories}
            className="calories-slider"
            onChange={(e) => handleCaloriesChange(e.target.value)}
          />
          <input
            type="number"
            min="0"
            max="5000"
            value={calories}
            className="calories-input"
            onChange={(e) => handleCaloriesChange(e.target.value)}
          />
        </div>
        {showSortByNew && (
          <button className="filter-option" onClick={handleSortByNew}>
            Сортировать по: Новизне
          </button>
        )}
      </div>
    </div>
  );
};

export default FilterBar;
