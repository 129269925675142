import React, { useState, useMemo } from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import './MultiRecipeSelectionModal.css';

const MultiRecipeSelectionModal = ({ recipes, onClose }) => {
  const [selectedRecipes, setSelectedRecipes] = useState([]);
  const [portions, setPortions] = useState({});
  const [activeTab, setActiveTab] = useState('recipes');
  const [isReportVisible, setIsReportVisible] = useState(false);
  const [deletedProducts, setDeletedProducts] = useState([]);

  // Обработчик выбора рецепта
  const handleSelectRecipe = (recipe) => {
    setSelectedRecipes((prev) => {
      const isSelected = prev.some((r) => r.id === recipe.id);
      return isSelected
        ? prev.filter((r) => r.id !== recipe.id)
        : [...prev, recipe];
    });
  };

  // Обработчик изменения количества порций для рецепта
  const handlePortionsChange = (recipeId, value) => {
    const parsedValue = parseInt(value, 10);
    if (isNaN(parsedValue) || parsedValue < 1) {
      alert('Количество порций должно быть положительным числом.');
      return;
    }

    setPortions((prev) => ({
      ...prev,
      [recipeId]: parsedValue,
    }));
  };

  // Расчет общей цены для рецепта
  const calculateTotalPrice = useMemo(() => {
    return (recipe) => {
      const totalNetto = recipe.ingredients.reduce(
        (sum, ing) => sum + parseFloat(ing.netto),
        0
      );
      return (totalNetto * (portions[recipe.id] || 1)).toFixed(2);
    };
  }, [portions]);

  // Обработчик удаления продукта
  const handleDeleteProduct = (productName) => {
    const productRow = document.querySelector(`.product-row[data-name="${productName}"]`);
    if (productRow) {
      productRow.classList.add('deleting');
    }

    setTimeout(() => {
      setDeletedProducts((prev) => [...prev, productName]);
    }, 300);
  };

  // Обработчик смены вкладки
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setDeletedProducts([]);
  };

  // Формирование отчета по продуктам
  const generateProductReport = () => {
    const allIngredients = selectedRecipes.flatMap((recipe) =>
      recipe.ingredients.map((ing) => ({
        name: ing.name,
        brutto: parseFloat(ing.brutto) * (portions[recipe.id] || 1),
        category: ing.category || 'Другое', // Добавляем категорию
      }))
    );

    // Группируем продукты по категориям
    const groupedByCategory = allIngredients.reduce((acc, ing) => {
      const category = ing.category || 'Другое';
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(ing);
      return acc;
    }, {});

    // Суммируем одинаковые продукты внутри каждой категории
    const sortedIngredients = Object.entries(groupedByCategory).map(([category, ingredients]) => {
      const summedIngredients = ingredients.reduce((acc, ing) => {
        const existing = acc.find((item) => item.name === ing.name);
        if (existing) {
          existing.brutto += ing.brutto;
        } else {
          acc.push({ ...ing });
        }
        return acc;
      }, []);
      return { category, ingredients: summedIngredients };
    });

    // Копирование списка в буфер обмена
    const handleCopyList = () => {
      const productList = sortedIngredients
        .map(({ category, ingredients }) => {
          const categoryProducts = ingredients
            .filter((ing) => !deletedProducts.includes(ing.name))
            .map((ing) => `${ing.name}: ${ing.brutto.toFixed(2)} г`)
            .join('\n');
          return `${category}:\n${categoryProducts}`;
        })
        .join('\n\n');

      navigator.clipboard.writeText(productList)
        .then(() => alert('Список скопирован в буфер обмена!'))
        .catch(() => alert('Не удалось скопировать список.'));
    };

    return (
      <div className="product-report">
        <h3>Заказ продуктов</h3>

        {sortedIngredients.map(({ category, ingredients }) => (
          <div key={category} className="category-section">
            <h4>{category}</h4>
            <table>
              <thead>
                <tr>
                  <th>Продукт</th>
                  <th>Общий</th>
                  <th>Убрать</th>
                </tr>
              </thead>
              <tbody>
                {ingredients
                  .filter((ing) => !deletedProducts.includes(ing.name))
                  .map((ing, idx) => (
                    <tr key={idx} className="product-row" data-name={ing.name}>
                      <td>{ing.name}</td>
                      <td>{ing.brutto.toFixed(2)}</td>
                      <td>
                        <button
                          onClick={() => handleDeleteProduct(ing.name)}
                          className="delete-button"
                        >
                          <span className="delete-icon">×</span>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
 
          </div>
          
        ))}
                   <button onClick={handleCopyList} className="copy-list-button">
          Скопировать список
        </button>
      </div>
    );
  };

  // Формирование отчета в модальном окне
  const generateReportInModal = () => {
    return (
      <div className="recipe-report-grid">
        {selectedRecipes.map((recipe) => {
          const multipliedIngredients = recipe.ingredients.map((ing) => ({
            name: ing.name,
            brutto: (parseFloat(ing.brutto) * (portions[recipe.id] || 1)).toFixed(2),
            netto: (parseFloat(ing.netto) * (portions[recipe.id] || 1)).toFixed(2),
          }));

          return (
            <div key={recipe.id} className="recipe-report-card">
              <h3>{recipe.name}</h3>
              <table>
                <thead>
                  <tr>
                    <th>Продукт</th>
                    <th>Брутто</th>
                    <th>Нетто</th>
                  </tr>
                </thead>
                <tbody>
                  {multipliedIngredients.map((ing, idx) => (
                    <tr key={idx}>
                      <td>{ing.name}</td>
                      <td>{ing.brutto}</td>
                      <td>{ing.netto}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    );
  };

  // Формирование отчета в формате Excel
  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Лист 1: Отчет по рецептам
    const recipeSheetData = [];
    recipeSheetData.push(['Название рецепта', 'Продукт', 'Брутто, г', 'Нетто, г']);

    selectedRecipes.forEach((recipe) => {
      recipeSheetData.push([recipe.name, '', '', '']);
      recipe.ingredients.forEach((ing) => {
        recipeSheetData.push([
          '',
          ing.name,
          (parseFloat(ing.brutto) * (portions[recipe.id] || 1)).toFixed(2),
          (parseFloat(ing.netto) * (portions[recipe.id] || 1)).toFixed(2),
        ]);
      });
      recipeSheetData.push(['', '', '', '']);
    });

    const recipeSheet = XLSX.utils.aoa_to_sheet(recipeSheetData);
    XLSX.utils.book_append_sheet(workbook, recipeSheet, 'Отчет по рецептам');

    // Лист 2: Заказ продуктов
    const productSheetData = [];
    productSheetData.push(['Продукт', 'Общая сумма, г']);

    const allIngredients = selectedRecipes.flatMap((recipe) =>
      recipe.ingredients.map((ing) => ({
        name: ing.name,
        brutto: parseFloat(ing.brutto) * (portions[recipe.id] || 1),
      }))
    );

    const groupedIngredients = allIngredients.reduce((acc, ing) => {
      const existing = acc.find((item) => item.name === ing.name);
      if (existing) {
        existing.brutto += ing.brutto;
      } else {
        acc.push({ name: ing.name, brutto: ing.brutto });
      }
      return acc;
    }, []);

    groupedIngredients.forEach((ing) => {
      productSheetData.push([ing.name, ing.brutto.toFixed(2)]);
    });

    const productSheet = XLSX.utils.aoa_to_sheet(productSheetData);
    XLSX.utils.book_append_sheet(workbook, productSheet, 'Заказ продуктов');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, 'Отчет по рецептам.xlsx');
  };

  // Печать отчета
  const handlePrint = () => {
    const printContent = document.createElement('div');
    printContent.className = 'printable-report';

    // Создаем контейнер для таблиц
    const tablesContainer = document.createElement('div');
    tablesContainer.className = 'printable-tables-container';

    selectedRecipes.forEach((recipe) => {
      const tableWrapper = document.createElement('div');
      tableWrapper.className = 'printable-table-wrapper';

      // Таблица
      const table = document.createElement('table');
      table.className = 'printable-table';

      // Шапка таблицы с названием рецепта и количеством порций
      const titleRow = document.createElement('tr');
      const titleCell = document.createElement('th');
      titleCell.colSpan = 3; // Объединяем ячейки для заголовка
      titleCell.textContent = `${recipe.name} (Порций: ${portions[recipe.id] || 1})`;
      titleCell.style.textAlign = 'center';
      titleCell.style.fontWeight = 'bold';
      titleCell.style.backgroundColor = '#f2f2f2';
      titleRow.appendChild(titleCell);
      table.appendChild(titleRow);

      // Заголовок таблицы
      const headerRow = document.createElement('tr');
      ['Продукт', 'Брутто', 'Нетто'].forEach((header) => {
        const th = document.createElement('th');
        th.textContent = header;
        headerRow.appendChild(th);
      });
      table.appendChild(headerRow);

      // Данные таблицы
      recipe.ingredients.forEach((ing) => {
        const row = document.createElement('tr');

        // Product name
        const nameCell = document.createElement('td');
        nameCell.textContent = ing.name;
        row.appendChild(nameCell);

        // Brutto (multiplied by portions)
        const bruttoCell = document.createElement('td');
        bruttoCell.textContent = (parseFloat(ing.brutto) * (portions[recipe.id] || 1)).toFixed(2);
        row.appendChild(bruttoCell);

        // Netto (multiplied by portions)
        const nettoCell = document.createElement('td');
        nettoCell.textContent = (parseFloat(ing.netto) * (portions[recipe.id] || 1)).toFixed(2);
        row.appendChild(nettoCell);

        table.appendChild(row);
      });

      tableWrapper.appendChild(table);
      tablesContainer.appendChild(tableWrapper);
    });

    printContent.appendChild(tablesContainer);

    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write(`
      <html>
        <head>
          <title>Отчет для печати</title>
          <style>
            body { 
              font-family: Arial, sans-serif; 
              font-size: 8px; /* Уменьшаем шрифт для печати */
              margin: 0; 
              padding: 10px; 
            }
            .printable-report { 
              width: 100%; 
              height: 100%; 
            }
            .printable-tables-container {
              display: grid;
              grid-template-columns: repeat(3, 1fr); /* Три таблицы в ряд */
              gap: 5px; /* Отступы между таблицами */
            }
            .printable-table-wrapper {
              border: 1px solid #000; /* Бордер для контейнера таблицы */
              padding: 5px; /* Отступы внутри контейнера */
              page-break-inside: avoid; /* Не разрывать таблицы на страницы */
            }
            .printable-table { 
              width: 100%; 
              border-collapse: collapse; 
            }
            .printable-table th, 
            .printable-table td { 
              border: 1px solid #000; /* Бордер для ячеек */
              padding: 2px; /* Уменьшаем отступы */
              text-align: left; 
              height: 20px; /* Фиксированная высота строк */
            }
            .printable-table th { 
              background-color: #f2f2f2; 
            }
            @media print {
              body { 
                font-size: 8px; /* Уменьшаем шрифт для печати */
              }
              .printable-tables-container {
                grid-template-columns: repeat(3, 1fr); /* Три таблицы в ряд */
                gap: 5px; /* Отступы между таблицами */
              }
              .printable-table { 
                font-size: 8px; /* Уменьшаем шрифт для печати */
              }
            }
          </style>
        </head>
        <body>
          ${printContent.innerHTML}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Выберите рецепты и количество порций</h2>

        {/* Вкладки */}
        <div className="tabs">
          <button
            className={activeTab === 'recipes' ? 'active' : ''}
            onClick={() => handleTabChange('recipes')}
          >
            Отчет по рецептам
          </button>
          <button
            className={activeTab === 'products' ? 'active' : ''}
            onClick={() => handleTabChange('products')}
          >
            Заказ продуктов
          </button>
        </div>

        {/* Список рецептов в строку */}
        <div className="recipes-row">
          {recipes.map((recipe) => (
            <div
              key={recipe.id}
              className={`recipe-item ${
                selectedRecipes.some((r) => r.id === recipe.id) ? 'selected' : ''
              }`}
              onClick={() => handleSelectRecipe(recipe)}
            >
              <img
                src={recipe.slides[0] || 'https://via.placeholder.com/50'}
                alt={recipe.name}
                className="recipe-thumbnail"
              />
              <div className="recipe-info">
                <h3>{recipe.name}</h3>
                <input
                  type="number"
                  min="1"
                  value={portions[recipe.id] || ''}
                  onChange={(e) => handlePortionsChange(recipe.id, e.target.value)}
                />
                <span>{calculateTotalPrice(recipe)}</span>
              </div>
            </div>
          ))}
        </div>

        {/* Кнопка для переключения видимости отчета */}
        <button
          className="toggle-report-button"
          onClick={() => setIsReportVisible(!isReportVisible)}
        >
          {isReportVisible ? 'Скрыть отчет' : 'Показать отчет'}
        </button>

        {/* Отчет в модальном окне */}
        {isReportVisible && (
          <div className="report-section">
            {activeTab === 'recipes' && generateReportInModal()}
            {activeTab === 'products' && generateProductReport()}
          </div>
        )}

        {/* Кнопки управления */}
        <div className="modal-actions">
          <button className="export-button" onClick={exportToExcel}>
            Экспорт в Excel
          </button>
          <button className="print-button" onClick={handlePrint}>
            Печать
          </button>
          <button className="modal-close-button" onClick={onClose}>
            Закрыть
          </button>
        </div>
      </div>
    </div>
  );
};

export default MultiRecipeSelectionModal;
