// src/utils/imgbbUploader.js
const IMGBB_API_KEY = 'a2fdfe2992029432fd86ee517cf78ca9'; // Получите на imgbb.com

export const uploadToImgBB = async (file) => {
  try {
    const base64 = await convertToBase64(file);
    const formData = new FormData();
    formData.append('image', base64.split(',')[1]);
    
    const response = await fetch(`https://api.imgbb.com/1/upload?key=${IMGBB_API_KEY}`, {
      method: 'POST',
      body: formData
    });
    
    const data = await response.json();
    return data.data.url;
  } catch (error) {
    console.error('Error uploading to ImgBB:', error);
    throw error;
  }
};

const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
