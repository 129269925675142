
import React, { useState, useMemo, useEffect } from 'react';
import IngredientModal from '../Modals/IngredientModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import './IngredientsList.css';

function IngredientsList({ ingredients, setIngredients, onTotalCaloriesChange }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [showNutrition, setShowNutrition] = useState(false);

  const warehouseIngredients = useMemo(() => {
    try {
      const warehouseData = JSON.parse(localStorage.getItem('warehouseCategories')) || [];
      return warehouseData.flatMap(category => category.ingredients);
    } catch (error) {
      console.error('Error parsing warehouse data:', error);
      return [];
    }
  }, []);

  const calculateCost = (brutto, pricePerKg) => {
    if (!brutto || !pricePerKg) return '';
    return Math.round((brutto / 1000) * pricePerKg);
  };

  const getWarehouseIngredient = (name) => {
    return warehouseIngredients.find(ing => ing.name === name);
  };

  const handleAddIngredient = () => {
    setSelectedIngredient(null);
    setShowModal(true);
  };

  const handleRowClick = (ingredient) => {
    setSelectedIngredient(ingredient);
    setShowModal(true);
  };

  const calculateBrutto = (netto, wastePercent) => {
    if (!netto) return '';
    if (!wastePercent || wastePercent === 0) return netto;
    return Math.round(netto / (1 - wastePercent / 100));
  };

  const calculateReadyWeight = (netto, waste) => {
    if (!netto || !waste) return '';
    return Math.round(netto * (100 - waste) / 100);
  };

  const calculateNutrition = (valuePer100g, weight) => {
    if (!valuePer100g || !weight) return 0;
    return ((valuePer100g * weight) / 100).toFixed(1);
  };

  const handleSave = (newIngredient) => {
    const warehouseIngredient = getWarehouseIngredient(newIngredient.name);
    let updatedIngredient = { ...newIngredient };

    if (warehouseIngredient) {
      updatedIngredient = {
        ...newIngredient,
        brutto: calculateBrutto(newIngredient.netto, warehouseIngredient.coldWaste),
        cost: calculateCost(calculateBrutto(newIngredient.netto, warehouseIngredient.coldWaste), warehouseIngredient.price),
        readyWeight: calculateReadyWeight(newIngredient.netto, newIngredient.waste),
        calories: calculateNutrition(warehouseIngredient.calories, calculateReadyWeight(newIngredient.netto, newIngredient.waste)),
        proteins: calculateNutrition(warehouseIngredient.proteins, calculateReadyWeight(newIngredient.netto, newIngredient.waste)),
        fats: calculateNutrition(warehouseIngredient.fats, calculateReadyWeight(newIngredient.netto, newIngredient.waste)),
        carbs: calculateNutrition(warehouseIngredient.carbs, calculateReadyWeight(newIngredient.netto, newIngredient.waste)),
      };
    }

    if (selectedIngredient) {
      setIngredients(ingredients.map(ing =>
        ing.id === selectedIngredient.id ? updatedIngredient : ing
      ));
    } else {
      setIngredients([...ingredients, updatedIngredient]);
    }

    setShowModal(false);
  };

  const handleDelete = (ingredientId) => {
    setIngredients(ingredients.filter(ing => ing.id !== ingredientId));
    setShowModal(false);
  };

  const calculateTotalNutrition = () => {
    return ingredients.reduce(
      (acc, ingredient) => {
        acc.calories += parseFloat(ingredient.calories || 0);
        acc.proteins += parseFloat(ingredient.proteins || 0);
        acc.fats += parseFloat(ingredient.fats || 0);
        acc.carbs += parseFloat(ingredient.carbs || 0);
        return acc;
      },
      { calories: 0, proteins: 0, fats: 0, carbs: 0 }
    );
  };

  useEffect(() => {
    if (typeof onTotalCaloriesChange === 'function') {
      onTotalCaloriesChange(calculateTotalNutrition().calories);
    }
  }, [ingredients, onTotalCaloriesChange]);

  const calculateTotalWeight = () => {
    return ingredients.reduce((sum, ing) => sum + (parseInt(ing.readyWeight) || 0), 0);
  };

  const calculateTotalCost = () => {
    return ingredients.reduce((sum, ing) => sum + (parseInt(ing.cost) || 0), 0);
  };

  const calculateTotalBrutto = () => {
    return ingredients.reduce((sum, ing) => sum + (parseInt(ing.brutto) || 0), 0);
  };

  const calculateTotalNetto = () => {
    return ingredients.reduce((sum, ing) => sum + (parseInt(ing.netto) || 0), 0);
  };

  const totalsNutrition = showNutrition ? calculateTotalNutrition() : {};
  const totalsBrutto = calculateTotalBrutto();
  const totalsNetto = calculateTotalNetto();

  return (
    <div className="ingredients-container">
      <button 
        className="toggle-button"
        onClick={() => setShowNutrition(!showNutrition)}
      >
        {showNutrition ? "Показать брутто/нетто" : "Показать КБЖУ"}
      </button>

      <div className="ingredients-header">
        <div className="ingredient-name">Ингредиент</div>
        {showNutrition ? (
          <>
            <div className="ingredient-ready">Выход</div>
            <div className="ingredient-calories">Кал</div>
            <div className="ingredient-proteins">Белки</div>
            <div className="ingredient-fats">Жиры</div>
            <div className="ingredient-carbs">Угл.</div>
          </>
        ) : (
          <>
            <div className="ingredient-brutto">Брутто</div>
            <div className="ingredient-netto">Нетто</div>
            <div className="ingredient-waste">%</div>
            <div className="ingredient-ready">Выход</div>
            <div className="ingredient-cost">Цена</div>
          </>
        )}
      </div>

      <div className="ingredients-list">
        {ingredients.map((ingredient) => (
          <div 
            key={ingredient.id} 
            className="ingredient-row"
            onClick={() => handleRowClick(ingredient)}
            style={{ cursor: 'pointer' }}
          >
            <div className="ingredient-name">{ingredient.name}</div>
            {showNutrition ? (
              <>
                <div className="ingredient-ready">{ingredient.readyWeight || '-'}</div>
                <div className="ingredient-calories">{ingredient.calories || '-'}</div>
                <div className="ingredient-proteins">{ingredient.proteins || '-'}</div>
                <div className="ingredient-fats">{ingredient.fats || '-'}</div>
                <div className="ingredient-carbs">{ingredient.carbs || '-'}</div>
              </>
            ) : (
              <>
                <div className="ingredient-brutto">{ingredient.brutto || '-'}</div>
                <div className="ingredient-netto">{ingredient.netto || '-'}</div>
                <div className="ingredient-waste">{ingredient.waste || '-'}</div>
                <div className="ingredient-ready">{ingredient.readyWeight || '-'}</div>
                <div className="ingredient-cost">{ingredient.cost || '-'}</div>
              </>
            )}
          </div>
        ))}

        {!showNutrition && (
          <div className="ingredient-row totals-row">
            <div className="ingredient-name totals-label">Итого:</div>
            <div className="ingredient-brutto">{totalsBrutto} г</div>
            <div className="ingredient-netto">{totalsNetto} г</div>
            <div className="ingredient-waste">-</div>
            <div className="ingredient-ready">{calculateTotalWeight()} г</div>
            <div className="ingredient-cost">{calculateTotalCost()} ₽</div>
          </div>
        )}

        {showNutrition && (
          <div className="ingredient-row totals-row">
            <div className="ingredient-name totals-label">Итого:</div>
            <div className="ingredient-ready">{calculateTotalWeight()} г</div>
            <div className="ingredient-calories">{totalsNutrition.calories.toFixed(2)}</div>
            <div className="ingredient-proteins">{totalsNutrition.proteins.toFixed(2)}</div>
            <div className="ingredient-fats">{totalsNutrition.fats.toFixed(2)}</div>
            <div className="ingredient-carbs">{totalsNutrition.carbs.toFixed(2)}</div>
          </div>
        )}

        <button 
          className="add-ingredient-button"
          onClick={handleAddIngredient}
        >
          <FontAwesomeIcon icon={faPlus} /> Добавить ингредиент
        </button>
      </div>

      {showModal && (
        <IngredientModal
          ingredient={selectedIngredient}
          onClose={() => setShowModal(false)}
          onSave={handleSave}
          onDelete={handleDelete}
          warehouseIngredients={warehouseIngredients}
        />
      )}
    </div>
  );
}

export default IngredientsList;