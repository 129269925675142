// src/components/Tabs/Tabs.js
import React, { useState } from 'react';
import StepModal from '../Modals/StepModal';
import './Tabs.css';



// import { FFmpeg } from '@ffmpeg/ffmpeg';
function Tabs({ steps, setSteps, recipe, setRecipe, videos, setVideos, videoFiles, setVideoFiles, mediaUrls, setMediaUrls }) {
  const [activeTab, setActiveTab] = useState('cooking');
  const [showStepModal, setShowStepModal] = useState(false);
  const [selectedStep, setSelectedStep] = useState(null);
  const fileInputRef = React.createRef(); // Создаем реф для input

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleAddStep = () => {
    setSelectedStep(null);
    setShowStepModal(true);
  };

  const handleEditStep = (step) => {
    setSelectedStep(step);
    setShowStepModal(true);
  };

  const handleSaveStep = (text) => {
    if (selectedStep) {
      setSteps(steps.map(s => s === selectedStep ? { text } : s));
    } else {
      setSteps([...steps, { text }]);
    }
    setShowStepModal(false);
  };

  const handleDeleteStep = () => {
    if (selectedStep) {
      setSteps(steps.filter(s => s !== selectedStep));
      setShowStepModal(false);
    }
  };


  // const ffmpeg = new FFmpeg({ log: true });

  // const compressVideo = async (file) => {
  //   if (!ffmpeg.isLoaded()) {
  //     await ffmpeg.load();
  //   }
  
  //   const arrayBuffer = await file.arrayBuffer();
  //   ffmpeg.FS('writeFile', 'input.mp4', new Uint8Array(arrayBuffer));
  
  //   // Adjust the ffmpeg command to include maximum compression and duration limit
  //   await ffmpeg.run(
  //     '-i', 'input.mp4',
  //     '-vcodec', 'libx264',
  //     '-crf', '35', // Increase CRF for more compression
  //     '-b:v', '300k', // Lower bitrate for more compression
  //     '-vf', 'scale=640:-1',
  //     '-t', '30', // Limit duration to 30 seconds
  //     'output.mp4'
  //   );
  
  //   const data = ffmpeg.FS('readFile', 'output.mp4');
  //   return new Blob([data.buffer], { type: 'video/mp4' });
  // };
  
  // const handleVideoUpload = async (event) => {
  //   const files = Array.from(event.target.files);
  //   const compressedFiles = [];
  
  //   for (const file of files) {
  //     if (file.size > 5 * 1024 * 1024) {
  //       alert('Файл слишком большой и будет сжат.');
  //       const compressedFile = await compressVideo(file);
  //       compressedFiles.push(compressedFile);
  //     } else {
  //       compressedFiles.push(file);
  //     }
  //   }
  
  //   const newVideos = compressedFiles.map(file => URL.createObjectURL(file));
  //   setVideos([...videos, ...newVideos]);
  //   setVideoFiles(prevFiles => [...prevFiles, ...compressedFiles]);
  // };
  // const triggerFileInput = () => {
  //   fileInputRef.current.click(); // Программно вызываем клик на input
  // };

  return (
    <div className="tabs-container">
      <div className="tabs">
        <button 
          className={`tab-btn ${activeTab === 'cooking' ? 'active' : ''}`}
          onClick={() => handleTabClick('cooking')}
        >
          Приготовление
        </button>
        <button 
          className={`tab-btn ${activeTab === 'recipe' ? 'active' : ''}`}
          onClick={() => handleTabClick('recipe')}
        >
          Рецепт
        </button>
        {/* <button 
          className={`tab-btn ${activeTab === 'video' ? 'active' : ''}`}
          onClick={() => handleTabClick('video')}
        >
          Видео
        </button> */}
      </div>
      {activeTab === 'cooking' && (
        <div className="tab-content active">
          <button className="add-step" onClick={handleAddStep}>
            Добавить описание
          </button>
          <ol className="steps-list">
            {steps.map((step, index) => (
              <li key={index} onClick={() => handleEditStep(step)}>
                {step.text}
              </li>
            ))}
          </ol>
        </div>
      )}
      {activeTab === 'recipe' && (
        <div className="tab-content active">
          <textarea
            className="recipe-text"
            value={recipe}
            onChange={(e) => setRecipe(e.target.value)}
            placeholder="Введите рецепт..."
          />
        </div>
      )}
      {/* {activeTab === 'video' && (
        <div className="tab-content active">
          <div className="video-gallery">
            <input 
              type="file" 
              accept="video/*" 
              multiple 
              onChange={handleVideoUpload} 
              style={{ display: 'none' }} // Скрываем input
              ref={fileInputRef} // Привязываем реф
            />
            <button className="add-video" onClick={triggerFileInput}>
              <i className="fas fa-video"></i> Добавить видео
            </button>
            <div className="video-list">
              {videos.map((video, index) => (
                <div key={index} className="video-item">
                  <video src={video} controls width="300" />
                  <button 
                    className="remove-video"
                    onClick={() => {
                      const newVideos = [...videos];
                      newVideos.splice(index, 1);
                      setVideos(newVideos);

                      const newVideoFiles = [...videoFiles];
                      newVideoFiles.splice(index, 1);
                      setVideoFiles(newVideoFiles);
                    }}
                  >
                    Удалить
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      )} */}
      {showStepModal && (
        <StepModal
          step={selectedStep}
          onClose={() => setShowStepModal(false)}
          onSave={handleSaveStep}
          onDelete={handleDeleteStep}
        />
      )}
    </div>
  );
}

export default Tabs;
