import React, { useState, useEffect } from 'react';
import { getWarehouse, saveWarehouse, deleteWarehouseItem } from '../firebaseFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPlus, 
  faChevronDown, 
  faChevronRight, 
  faDownload, 
  faTrash, 
  faUpload, 
  faArrowsAltV 
} from '@fortawesome/free-solid-svg-icons';
import WarehouseIngredientModal from '../components/Modals/WarehouseIngredientModal';
import CategoryModal from '../components/Modals/CategoryModal';
import './Warehouse.css';
import * as XLSX from 'xlsx';

function Warehouse() {
  const [categories, setCategories] = useState(() => {
    const cachedCategories = localStorage.getItem('warehouseCategories');
    return cachedCategories ? JSON.parse(cachedCategories) : [];
  });
  const [showIngredientModal, setShowIngredientModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filter, setFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSynced, setIsSynced] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isBaseLoading, setIsBaseLoading] = useState(false);
  
  // Удалено состояние для модального окна подтверждения
  // const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    if (categories.length === 0) {
      fetchWarehouse();
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('warehouseCategories', JSON.stringify(categories));
  }, [categories]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!isSynced) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isSynced]);

  const fetchWarehouse = async () => {
    setIsLoading(true);
    try {
      const warehouseData = await getWarehouse();
      setCategories(warehouseData);
      localStorage.setItem('warehouseCategories', JSON.stringify(warehouseData));
    } catch (error) {
      console.error('Ошибка при загрузке данных склада:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddCategory = (newCategory) => {
    const categoryId = Date.now().toString() + Math.random().toString(36).substr(2, 9);
    const updatedCategories = [...categories, { ...newCategory, id: categoryId, ingredients: [], isOpen: true }];
    setCategories(updatedCategories);
    setIsSynced(false);
    setShowCategoryModal(false);
  };

  const handleDeleteCategory = (categoryId) => {
    const updatedCategories = categories.filter(cat => cat.id !== categoryId);
    setCategories(updatedCategories);
    setIsSynced(false);
    setShowCategoryModal(false);
  };

  const handleEditCategory = (editedCategory) => {
    const updatedCategories = categories.map(cat => 
      cat.id === editedCategory.id ? { ...cat, name: editedCategory.name } : cat
    );
    setCategories(updatedCategories);
    setIsSynced(false);
    setShowCategoryModal(false);
  };

  const handleAddIngredient = (newIngredient) => {
    const ingredientWithDefaults = {
      ...newIngredient,
      price: parseFloat(newIngredient.price) || 0,
      coldWaste: parseFloat(newIngredient.coldWaste) || 0,
      calories: parseFloat(newIngredient.calories) || 0,
      proteins: parseFloat(newIngredient.proteins) || 0,
      fats: parseFloat(newIngredient.fats) || 0,
      carbs: parseFloat(newIngredient.carbs) || 0
    };

    const ingredientId = Date.now().toString() + Math.random().toString(36).substr(2, 9);
    ingredientWithDefaults.id = ingredientId;

    const updatedCategories = categories.map(cat => 
      cat.id === selectedCategory.id 
        ? { ...cat, ingredients: [...cat.ingredients, ingredientWithDefaults] }
        : cat
    );
    setCategories(updatedCategories);
    setIsSynced(false);
    setShowIngredientModal(false);
  };

  const handleEditIngredient = (editedIngredient) => {
    const ingredientWithDefaults = {
      ...editedIngredient,
      price: parseFloat(editedIngredient.price) || 0,
      coldWaste: parseFloat(editedIngredient.coldWaste) || 0,
      calories: parseFloat(editedIngredient.calories) || 0,
      proteins: parseFloat(editedIngredient.proteins) || 0,
      fats: parseFloat(editedIngredient.fats) || 0,
      carbs: parseFloat(editedIngredient.carbs) || 0
    };

    const updatedCategories = categories.map(cat => ({
      ...cat,
      ingredients: cat.ingredients.map(ing => 
        ing.id === ingredientWithDefaults.id ? ingredientWithDefaults : ing
      )
    }));
    setCategories(updatedCategories);
    setIsSynced(false);
    setShowIngredientModal(false);
  };

  const handleDeleteIngredient = (ingredientId) => {
    const updatedCategories = categories.map(cat => ({
      ...cat,
      ingredients: cat.ingredients.filter(ing => ing.id !== ingredientId)
    }));
    setCategories(updatedCategories);
    setIsSynced(false);
    setShowIngredientModal(false);
  };

  const toggleCategory = (categoryId) => {
    setCategories(categories.map(cat => 
      cat.id === categoryId ? { ...cat, isOpen: !cat.isOpen } : cat
    ));
  };

  const toggleAllCategories = () => {
    const allOpen = categories.every(cat => cat.isOpen);
    setCategories(categories.map(cat => ({ ...cat, isOpen: !allOpen })));
  };

  const filteredCategories = categories.map(cat => ({
    ...cat,
    ingredients: cat.ingredients.filter(ing => 
      ing.name.toLowerCase().includes(filter.toLowerCase())
    )
  })).filter(cat => cat.ingredients.length > 0 || filter === '');

  const syncWithDatabase = async () => {
    setIsLoading(true);
    try {
      await saveWarehouse(categories);
      setIsSynced(true);
      alert('Изменения успешно сохранены!');
    } catch (error) {
      console.error('Ошибка при сохранении изменений:', error);
      alert('Произошла ошибка при сохранении изменений.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleExcelUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (!['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(file.type)) {
      setErrorMessage('Неверный формат файла. Пожалуйста, загрузите Excel файл.');
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      setErrorMessage('Размер файла превышает 5MB.');
      return;
    }

    const reader = new FileReader();
    reader.onload = (evt) => {
      try {
        const bstr = evt.target.result;
        const workbook = XLSX.read(bstr, { type: 'binary', codepage: 65001 });

        if (workbook.SheetNames.length === 0) {
          setCategories([]);
          setIsSynced(false);
          setErrorMessage('');
          alert('Пустой файл успешно загружен.');
          return;
        }

        const newCategories = workbook.SheetNames.map(sheetName => {
          const sheet = workbook.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          if (data.length === 0) {
            return {
              id: Date.now().toString() + Math.random().toString(36).substr(2, 9),
              name: sheetName,
              ingredients: [],
              isOpen: true,
            };
          }

          const categoryNameRow = data[0][0];
          const categoryName = categoryNameRow ? categoryNameRow.replace('Категория: ', '') : sheetName;

          const ingredients = data.slice(1).map(row => ({
            id: Date.now().toString() + Math.random().toString(36).substr(2, 9),
            name: row[0] || '',
            price: parseFloat(row[1]) || 0,
            coldWaste: parseFloat(row[2]) || 0,
            calories: parseFloat(row[3]) || 0,
            proteins: parseFloat(row[4]) || 0,
            fats: parseFloat(row[5]) || 0,
            carbs: parseFloat(row[6]) || 0,
          })).filter(ing => ing.name.trim() !== '');

          return {
            id: Date.now().toString() + Math.random().toString(36).substr(2, 9),
            name: categoryName,
            ingredients,
            isOpen: true,
          };
        });

        setCategories(newCategories);
        setIsSynced(false);
        setErrorMessage('');
        alert('Данные успешно загружены.');
      } catch (error) {
        console.error('Ошибка при загрузке Excel файла:', error);
        setErrorMessage('Произошла ошибка при загрузке Excel файла.');
      }
    };
    reader.readAsBinaryString(file);
  };

  const handleJsonUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.type !== 'application/json') {
      setErrorMessage('Неверный формат файла. Пожалуйста, загрузите JSON файл.');
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      setErrorMessage('Размер файла превышает 5MB.');
      return;
    }

    setIsLoading(true);
    const reader = new FileReader();
    reader.onload = async (evt) => {
      try {
        const jsonData = JSON.parse(evt.target.result);
        if (!Array.isArray(jsonData)) {
          throw new Error('Неверная структура JSON файла.');
        }
        setCategories(jsonData);
        setIsSynced(false);
        setErrorMessage('');
        alert('Данные успешно загружены!');
      } catch (error) {
        console.error('Ошибка при обработке JSON файла:', error);
        setErrorMessage('Произошла ошибка при обработке JSON файла.');
      } finally {
        setIsLoading(false);
      }
    };
    reader.readAsText(file);
  };

  const handleJsonDownload = () => {
    const jsonData = JSON.stringify(categories, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'warehouse.json';
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleFileDownload = () => {
    const workbook = XLSX.utils.book_new();

    const cleanSheetName = (name) => {
      return name.replace(/[:\\/?*[\]]/g, '').substring(0, 31);
    };

    categories.forEach(category => {
      const worksheetData = category.ingredients.map(ingredient => ({
        "Название": ingredient.name,
        "Цена за 1 кг": `${ingredient.price} руб.`,
        "% отхода": `${ingredient.coldWaste}%`,
        "Калории": ingredient.calories,
        "Белки": ingredient.proteins,
        "Жиры": ingredient.fats,
        "Угл.": ingredient.carbs
      }));

      const worksheet = XLSX.utils.json_to_sheet(worksheetData);

      XLSX.utils.sheet_add_aoa(worksheet, [[`Категория: ${category.name}`]], { origin: 'A1' });

      const columnWidths = [
        { wch: 20 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 }
      ];
      worksheet['!cols'] = columnWidths;
      
      const sheetNameClean = cleanSheetName(category.name);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetNameClean);
    });

    XLSX.writeFile(workbook, 'Склад.xlsx');
  };

  const handleRemoveDuplicates = () => {
    const uniqueCategories = Array.from(new Set(categories.map(cat => cat.id)))
      .map(id => categories.find(cat => cat.id === id));
    setCategories(uniqueCategories);
    setIsSynced(false);
  };

  const loadBaseWarehouse = async () => {
    setIsBaseLoading(true);
    try {
      const response = await fetch('/base_warehouse.xlsx');
      if (!response.ok) {
        throw new Error('Не удалось загрузить файл базового склада.');
      }
      const blob = await response.blob();

      if (blob.size === 0) {
        setCategories([]);
        setIsSynced(false);
        setErrorMessage('');
        alert('Базовый склад загружен пустым.');
        return;
      }

      if (!['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(blob.type)) {
        setErrorMessage('Неверный формат файла. Пожалуйста, загрузите Excel файл.');
        return;
      }

      const file = new File([blob], 'base_warehouse.xlsx', { type: blob.type });

      const event = {
        target: {
          files: [file]
        }
      };

      await handleExcelUpload(event);
    } catch (error) {
      console.error('Ошибка при загрузке базового склада:', error);
      setErrorMessage('Ошибка при загрузке базового склада.');
    } finally {
      setIsBaseLoading(false);
    }
  };

  // Измененная функция handleBaseWarehouseClick для прямой загрузки файла
  const handleBaseWarehouseClick = () => {
    // Инициация загрузки файла base_warehouse.xlsx через создание и клик по ссылке <a>
    const link = document.createElement('a');
    link.href = '/base_warehouse.xlsx'; // Путь до вашего файла
    link.download = 'base_warehouse.xlsx'; // Имя файла при загрузке
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Если также требуется загрузить данные склада в приложение, раскомментируйте следующую строку
    // loadBaseWarehouse();
  };

  return (
    <div className="warehouse">
      <div className="warehouse-header">
        <input 
          type="text" 
          placeholder="Фильтр ингредиентов" 
          value={filter} 
          onChange={(e) => setFilter(e.target.value)}
          className="filter-input"
        />
        <button onClick={toggleAllCategories} className="toggle-all-btn">
          Раскрыть <FontAwesomeIcon icon={faArrowsAltV} /> 
        </button>
        <button onClick={() => setShowCategoryModal(true)} className="add-category-btn">
          Добавить категорию
        </button>
      </div>

      <button 
        onClick={handleBaseWarehouseClick} 
        className="load-base-warehouse-btn"
        disabled={isBaseLoading}
      >
        <FontAwesomeIcon icon={faUpload} /> 
        {isBaseLoading ? 'Загрузка...' : 'Загрузить базовый склад'}
        {isBaseLoading && <div className="loading-spinner"></div>}
      </button>
      <span className='load-base-warehouse-btn-warning'> - проверяйте информацию, данные могут быть не точными!</span>

      {/* Удалено модальное окно подтверждения
      {showConfirmationModal && (
        <div className="confirmation-modal">
          <div className="confirmation-modal-content">
            <h3>Внимание!</h3>
            <p>Перед загрузкой базового склада рекомендуется сохранить текущие данные. Хотите скачать текущую базу?</p>
            <div className="confirmation-modal-buttons">
              <button onClick={handleConfirmBaseWarehouse} className="confirm-btn">
                Да, скачать и загрузить
              </button>
              <button onClick={handleCancelBaseWarehouse} className="cancel-btn">
                Отмена
              </button>
            </div>
          </div>
        </div>
      )}
      */}

      {isLoading && <div className="loading-spinner">...</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {filteredCategories.map(category => (
        <div key={category.id} className="category">
          <div className="category-header">
            <FontAwesomeIcon 
              icon={category.isOpen ? faChevronDown : faChevronRight} 
              onClick={() => toggleCategory(category.id)}
            />
            <h2 onClick={() => {
              setSelectedCategory(category);
              setShowCategoryModal(true);
            }}>{category.name}</h2>
          </div>
          {category.isOpen && (
            <div className="category-content">
              <table className="ingredients-table">
                <thead>
                  <tr>
                    <th>Название</th>
                    <th>Цена</th>
                    <th>% </th>
                    <th>Кк</th>
                    <th>Б</th>
                    <th>Ж</th>
                    <th>У</th>
                  </tr>
                </thead>
                <tbody>
                  {category.ingredients.map(ingredient => (
                    <tr key={ingredient.id} onClick={() => {
                      setSelectedIngredient(ingredient);
                      setSelectedCategory(category);
                      setShowIngredientModal(true);
                    }}>
                      <td>{ingredient.name}</td>
                      <td>{ingredient.price || 0} р</td>
                      <td>{ingredient.coldWaste || 0}%</td>
                      <td className="calories">{ingredient.calories || 0}</td>
                      <td className="proteins">{ingredient.proteins || 0}</td>
                      <td className="fats">{ingredient.fats || 0}</td>
                      <td className="carbs">{ingredient.carbs || 0}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button onClick={() => {
                setSelectedCategory(category);
                setSelectedIngredient(null);
                setShowIngredientModal(true);
              }} className="add-ingredient-btn">
                <FontAwesomeIcon icon={faPlus} /> 
              </button>
            </div>
          )}
        </div>
      ))}

      {showIngredientModal && (
        <WarehouseIngredientModal
          ingredient={selectedIngredient}
          onClose={() => setShowIngredientModal(false)}
          onSave={selectedIngredient ? handleEditIngredient : handleAddIngredient}
          onDelete={handleDeleteIngredient}
        />
      )}
      {showCategoryModal && (
        <CategoryModal
          category={selectedCategory}
          onClose={() => setShowCategoryModal(false)}
          onSave={selectedCategory ? handleEditCategory : handleAddCategory}
          onDelete={handleDeleteCategory}
        />
      )}

      <button onClick={syncWithDatabase} disabled={isSynced}>
        Сохранить изменения
      </button>

      <div className="file-actions">
        <label htmlFor="excel-upload" className="file-upload-label">
          <FontAwesomeIcon icon={faUpload} /> Загрузить склад из Excel
          <input 
            type="file" 
            id="excel-upload" 
            onChange={handleExcelUpload} 
            accept=".xlsx, .xls" 
            style={{ display: 'none' }} 
          />
        </label>
        {/* 
        <label htmlFor="json-upload" className="file-upload-label">
          <FontAwesomeIcon icon={faUpload} /> Загрузить склад из JSON
          <input 
            type="file" 
            id="json-upload" 
            onChange={handleJsonUpload} 
            accept=".json" 
            style={{ display: 'none' }} 
          />
        </label> 
        */}
        <button onClick={handleFileDownload}>
          <FontAwesomeIcon icon={faDownload} /> Выгрузить склад в Excel
        </button>
        {/* 
        <button onClick={handleJsonDownload}>
          <FontAwesomeIcon icon={faDownload} /> Выгрузить склад в JSON
        </button> 
        */}
        <button onClick={handleRemoveDuplicates}>
          <FontAwesomeIcon icon={faTrash} /> Удалить дубликаты
        </button>
      </div>
    </div>
  );
}

export default Warehouse;
