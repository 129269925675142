// firebaseFunctions.js
// Adjusted to fix any potential issues and make sure all functions are defined

import { db, auth } from './firebase';
import {
  collection,
  getDocs,
  query,
  where,
  Timestamp,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';

export const updateUserBalance = async (userId, amount) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const currentBalance = userDoc.data().balance || 0;
      await updateDoc(userRef, { balance: currentBalance + amount });
      console.log("User balance updated successfully");
    } else {
      throw new Error("User document does not exist");
    }
  } catch (e) {
    console.error("Error updating user balance: ", e);
    throw e;
  }
};

export const deleteSharedRecipe = async (recipeId) => {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error("User not authenticated");
    }

    const userDocRef = doc(db, 'users', user.uid);
    const userDocSnap = await getDoc(userDocRef);

    if (!userDocSnap.exists() || userDocSnap.data().role !== 'admin') {
      throw new Error("Only admins can delete recipes");
    }

    const recipeRef = doc(db, 'sharedRecipes', recipeId);
    await deleteDoc(recipeRef);
    console.log("Recipe deleted with ID: ", recipeId);
  } catch (e) {
    console.error("Error deleting shared recipe: ", e);
    throw e;
  }
};

export const updatePurchasedRecipes = async (userId, recipeId) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const purchasedRecipes = userData.purchasedRecipes || [];

      if (!purchasedRecipes.includes(recipeId)) {
        purchasedRecipes.push(recipeId);
        await updateDoc(userRef, { purchasedRecipes });
      }
    } else {
      throw new Error("User document does not exist");
    }
  } catch (e) {
    console.error("Error updating purchased recipes: ", e);
    throw e;
  }
};

export const saveRecipe = async (recipe) => {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error("User not authenticated");
    }
    console.log("Saving recipe with data:", recipe);

    const recipeRef = doc(db, 'recipes', recipe.id);
    const recipeDoc = await getDoc(recipeRef);

    if (recipeDoc.exists()) {
      await setDoc(recipeRef, {
        ...recipe,
        updatedAt: Timestamp.now()
      }, { merge: true });
      console.log("Document updated with ID: ", recipe.id);
    } else {
      await setDoc(recipeRef, {
        ...recipe,
        authorId: user.uid,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now()
      });
      console.log("Document written with ID: ", recipe.id);
    }
    return recipe.id;
  } catch (e) {
    console.error("Error adding/updating document: ", e);
    throw e;
  }
};

export const shareRecipe = async (recipe) => {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error("User not authenticated");
    }

    const userDocRef = doc(db, "users", user.uid);
    const userDocSnap = await getDoc(userDocRef);
    const userData = userDocSnap.data();

    const sharedRecipeRef = doc(db, 'sharedRecipes', recipe.id);
    await setDoc(sharedRecipeRef, {
      ...recipe,
      authorId: user.uid,
      authorAvatar: userData.avatar,
      authorName: userData.name,
      authorPosition: userData.position,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      likes: 0,
      dislikes: 0,
      saves: 0
    });
    console.log("Shared recipe written with ID:", recipe.id);

    alert("Рецепт улетел в бездну", recipe.id);
    return recipe.id;
  } catch (e) {
    console.error("Error sharing recipe:", e);
    throw e;
  }
};

export const getRecipes = async () => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not authenticated");
  }

  const q = query(collection(db, "recipes"), where("authorId", "==", user.uid));
  const querySnapshot = await getDocs(q);
  const recipes = [];
  querySnapshot.forEach((doc) => {
    recipes.push({ id: doc.id, ...doc.data() });
  });
  console.log("Fetched recipes:", recipes);
  return recipes;
};

export const deleteRecipe = async (recipeId) => {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error("User not authenticated");
    }

    const recipeRef = doc(db, 'recipes', recipeId);
    await deleteDoc(recipeRef);
    console.log("Document deleted with ID: ", recipeId);
  } catch (e) {
    console.error("Error deleting document: ", e);
    throw e;
  }
};

export const saveRows = async (rows) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not authenticated");
  }

  const userRef = doc(db, 'users', user.uid);
  await setDoc(userRef, { rows }, { merge: true });
};

export const getRows = async () => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not authenticated");
  }

  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);
  if (userDoc.exists()) {
    return userDoc.data().rows || 1;
  }
  return 1;
};

export const getWarehouse = async () => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not authenticated");
  }

  const warehouseRef = doc(db, "warehouse", user.uid);
  const warehouseSnap = await getDoc(warehouseRef);
  if (warehouseSnap.exists()) {
    return warehouseSnap.data().categories || [];
  } else {
    return [];
  }
};

export const saveWarehouse = async (categories) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not authenticated");
  }

  const warehouseRef = doc(db, "warehouse", user.uid);

  const cleanedCategories = categories.map(cat => ({
    id: cat.id || Date.now().toString(),
    name: cat.name || 'Без названия',
    ingredients: cat.ingredients.map(ing => ({
      id: ing.id || Date.now().toString(),
      name: ing.name || 'Без названия',
      price: ing.price || 0,
      coldWaste: ing.coldWaste || 0,
      calories: ing.calories || 0,
      proteins: ing.proteins || 0,
      fats: ing.fats || 0,
      carbs: ing.carbs || 0
    })),
    isOpen: cat.isOpen !== undefined ? cat.isOpen : true
  }));

  await setDoc(warehouseRef, { userId: user.uid, categories: cleanedCategories }, { merge: true });
};

export const deleteWarehouseItem = async (itemId, isCategory) => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("User not authenticated");
  }

  const warehouseRef = doc(db, "warehouse", user.uid);
  const warehouseSnap = await getDoc(warehouseRef);
  if (warehouseSnap.exists()) {
    const warehouseData = warehouseSnap.data();
    let updatedCategories;
    if (isCategory) {
      updatedCategories = warehouseData.categories.filter(cat => cat.id !== itemId);
    } else {
      updatedCategories = warehouseData.categories.map(cat => ({
        ...cat,
        ingredients: cat.ingredients.filter(ing => ing.id !== itemId)
      }));
    }

    await setDoc(warehouseRef, { categories: updatedCategories }, { merge: true });
  }
};

export const getIngredientFromDatabase = async (ingredientName) => {
  try {
    const q = query(
      collection(db, 'ingredients'),
      where('name', '==', ingredientName.toLowerCase())
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].data();
    }
    return null;
  } catch (error) {
    console.error('Error fetching ingredient from database:', error);
    throw error;
  }
};

export const saveIngredientToWarehouse = async (userId, ingredient, categoryId) => {
  try {
    const warehouseRef = doc(db, 'warehouse', userId);
    const warehouseSnap = await getDoc(warehouseRef);

    if (warehouseSnap.exists()) {
      const warehouseData = warehouseSnap.data();
      const categories = warehouseData.categories || [];

      const categoryIndex = categories.findIndex((cat) => cat.id === categoryId);
      if (categoryIndex !== -1) {
        categories[categoryIndex].ingredients.push({
          ...ingredient,
          id: Date.now().toString          + Math.random().toString(36).substr(2, 9),
        });
      } else {
        if (categories.length > 0) {
          categories[0].ingredients.push({
            ...ingredient,
            id: Date.now().toString() + Math.random().toString(36).substr(2, 9),
          });
        } else {
          categories.push({
            id: Date.now().toString(),
            name: 'Основные',
            ingredients: [
              {
                ...ingredient,
                id: Date.now().toString() + Math.random().toString(36).substr(2, 9),
              },
            ],
            isOpen: true,
          });
        }
      }

      await setDoc(warehouseRef, { categories }, { merge: true });
      console.log('Ingredient added to warehouse successfully');
      return true;
    } else {
      await setDoc(warehouseRef, {
        userId: userId,
        categories: [
          {
            id: Date.now().toString(),
            name: 'Основные',
            ingredients: [
              {
                ...ingredient,
                id: Date.now().toString() + Math.random().toString(36).substr(2, 9),
              },
            ],
            isOpen: true,
          },
        ],
      });
      console.log('Warehouse created and ingredient added successfully');
      return true;
    }
  } catch (error) {
    console.error('Error saving ingredient to warehouse:', error);
    throw error;
  }
};

export const saveCells = async (userId, cells) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, { cells: cells });
    console.log("User cells updated successfully");
  } catch (e) {
    console.error("Error updating user cells: ", e);
    throw e;
  }
};



