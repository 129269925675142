import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import React, { useState } from 'react';
import { auth, db } from '../../firebase';
import { doc, runTransaction } from 'firebase/firestore';

function AlternativePdfGenerator({ recipe }) {
    const [isLoading, setIsLoading] = useState(false);
    console.log("Переданные URL-ы изображений:", recipe.imageUrls);

    const deductCokens = async (amount) => {
        const user = auth.currentUser;
        if (!user) {
            alert('Пожалуйста, войдите в систему.');
            return false;
        }

        const userDocRef = doc(db, 'users', user.uid);

        try {
            await runTransaction(db, async (transaction) => {
                const userDoc = await transaction.get(userDocRef);
                if (!userDoc.exists()) {
                    throw new Error('Пользователь не найден.');
                }

                const currentBalance = userDoc.data().balance || 0;

                if (currentBalance < amount) {
                    throw new Error('Недостаточно кокенсов для генерации PDF.');
                }

                transaction.update(userDocRef, {
                    balance: currentBalance - amount
                });
            });
            return true;
        } catch (error) {
            alert(error.message);
            console.error("Ошибка при списании кокенсов:", error);
            return false;
        }
    };

    const generatePDF = async () => {
        setIsLoading(true);
        try {
            const canGenerate = await deductCokens(50);
            if (!canGenerate) {
                setIsLoading(false);
                return;
            }

            const doc = new jsPDF();
            doc.addFont('https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf', 'Roboto', 'normal');
            doc.setFont('Roboto');

            const pageWidth = doc.internal.pageSize.width;
            const margin = 15;
            let yPos = 10;

            // Заголовок
            doc.setFontSize(16);
            const title = "Технико-технологическая карта";
            const titleWidth = doc.getTextWidth(title);
            const titleX = (pageWidth - titleWidth) / 2;
            doc.text(title, titleX, yPos);
            yPos += 10;

            const recipeName = recipe.name;
            const recipeNameWidth = doc.getTextWidth(recipeName);
            const recipeNameX = (pageWidth - recipeNameWidth) / 2;
            doc.text(recipeName, recipeNameX, yPos);

            // Информация слева от главного слайда
            const currentDate = new Date().toLocaleDateString();
            doc.setFontSize(12);
            doc.text(`Дата: ${currentDate}`, margin, yPos + 35);
            doc.text("Подпись: _________________", margin, yPos + 55);
            doc.text("Расшифровка подписи: _______________________", margin, yPos + 65);

            // Главный слайд справа от заголовка
            const mainImageYOffset = yPos - -5;
            if (recipe.imageUrls && recipe.imageUrls.length > 0) {
                try {
                    const mainImageBase64 = await loadImageAsBase64(recipe.imageUrls[0]);
                    const imageWidth = 65;
                    const imageHeight = 65;
                    const imageXOffset = pageWidth - margin - imageWidth;
                    doc.roundedRect(imageXOffset, mainImageYOffset, imageWidth, imageHeight, 2, 2, 'S');
                    doc.addImage(mainImageBase64, "JPEG", imageXOffset + 2.5, mainImageYOffset + 2.5, imageWidth - 5, imageHeight - 5);
                } catch (error) {
                    console.error("Ошибка загрузки главного изображения:", error);
                }
            }

            // Добавление отступа
            yPos += 72;

            // Ингредиенты
            if (recipe.ingredients && recipe.ingredients.length > 0) {
                const ingredientsData = recipe.ingredients.map(ing => [
                    ing.name,
                    `${parseFloat(ing.brutto).toFixed(2)} г`,
                    `${parseFloat(ing.netto).toFixed(2)} г`,
                    `${parseFloat(ing.readyWeight).toFixed(2)} г`
                ]);

                const totalReadyWeight = recipe.ingredients.reduce((sum, ing) =>
                    sum + parseFloat(ing.readyWeight), 0
                ).toFixed(2);

                doc.autoTable({
                    startY: yPos,
                    head: [['Наименование сырья', 'Брутто, г', 'Нетто, г', 'Выход, г']],
                    body: ingredientsData,
                    theme: 'grid',
                    styles: {
                        font: 'Roboto',
                        fontSize: 10,
                        cellPadding: 1,
                        halign: 'left',
                        valign: 'middle',
                        overflow: 'linebreak',
                        cellWidth: 'wrap'
                    },
                    headStyles: {
                        fillColor: [200, 200, 200],
                        textColor: 20,
                        fontStyle: 'bold',
                        halign: 'center'
                    },
                    columnStyles: {
                        0: { cellWidth: 'auto' },
                        1: { cellWidth: 28, halign: 'center' },
                        2: { cellWidth: 28, halign: 'center' },
                        3: { cellWidth: 28, halign: 'center' }
                    },
                    margin: { left: margin, right: 10 },
                    tableWidth: 'auto'
                });

                yPos = doc.lastAutoTable.finalY + 5;
                doc.setFontSize(10);
                doc.text(`Общий выход: ${totalReadyWeight} г`, pageWidth - 10, yPos, { align: 'right' });
                yPos += 5;
            }

            // Шаги рецепта с изображениями
            if (recipe.steps) {
                doc.setFontSize(8);
                doc.text("Инструкции", margin, yPos);
                yPos += 5;
            
                const stepImageWidth = 20;
                const stepImageHeight = 20;
                const gap = 6;
                const stepsPerRow = 3;
                const stepTextWidth = (pageWidth - margin * 2 - stepImageWidth * stepsPerRow - gap * (stepsPerRow - 1)) / stepsPerRow;
            
                for (let i = 0; i < recipe.steps.length; i++) {
                    const step = recipe.steps[i];
                    const xPos = margin + (i % stepsPerRow) * (stepImageWidth + stepTextWidth + gap);
            
                    // Add border to the slide
                    doc.roundedRect(xPos, yPos, stepImageWidth, stepImageHeight, 1, 1, 'S');
            
                    if (recipe.imageUrls && recipe.imageUrls[i + 1]) {
                        try {
                            const base64Image = await loadImageAsBase64(recipe.imageUrls[i + 1]);
                            doc.addImage(base64Image, "JPEG", xPos + 1, yPos + 1, stepImageWidth - 2, stepImageHeight - 2);
                        } catch (error) {
                            console.error("Ошибка загрузки изображения шага:", error);
                        }
                    }
            
                    const stepText = `${i + 1}. ${step.text}`;
                    const splitStepText = doc.splitTextToSize(stepText, stepTextWidth);
                    doc.text(splitStepText, xPos + stepImageWidth + 2, yPos + 3, { align: 'left' });
            
                    // Update yPos after each row of steps
                    if ((i + 1) % stepsPerRow === 0) {
                        yPos += 25 + gap; // Move down after every row
                    }
                }
            
                // Ensure yPos is updated after the last row of steps
                if (recipe.steps.length % stepsPerRow !== 0) {
                    yPos += 25 + gap;
                }
            }
            
            // Описание рецепта
            if (recipe.description) {
                yPos += 10; // Add some space before the description
                doc.setFontSize(12);
                doc.text("Описание", margin, yPos);
                yPos += 5;
                doc.setFontSize(10);
                const descriptionText = doc.splitTextToSize(recipe.description, pageWidth - margin * 2);
                doc.text(descriptionText, margin, yPos);
            }

            // Скачивание PDF
            doc.save(`ТТК - ${recipe.name}.pdf`);
        } catch (error) {
            console.error("Ошибка при генерации PDF:", error);
            alert('Произошла ошибка при генерации PDF.');
        } finally {
            setIsLoading(false);
        }
    };

    // Helper function to load image as base64
    const loadImageAsBase64 = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                resolve(canvas.toDataURL('image/jpeg'));
            };
            img.onerror = reject;
            img.src = url;
        });
    };

    return (
       

        <button onClick={generatePDF} disabled={isLoading}>
            {isLoading ? 'Генерация...' : 'Рецептура 50 Cocens'}
        </button>
    );
}

export default AlternativePdfGenerator;
