import React, { useEffect, useState } from 'react';
import { collection, query, where, onSnapshot, doc, updateDoc, deleteDoc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import './AwaitingModeration.css';

const AwaitingModeration = () => {
  const [draftRecipes, setDraftRecipes] = useState([]);
  const [cokenRequests, setCokenRequests] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const recipeQuery = query(collection(db, 'sharedRecipes'), where('status', '==', 'draft'));
    const unsubscribeRecipes = onSnapshot(recipeQuery, (snapshot) => {
      const recipes = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setDraftRecipes(recipes);
    });

    const cokenQuery = query(collection(db, 'cokenRequests'));
    const unsubscribeCokenRequests = onSnapshot(cokenQuery, (snapshot) => {
      const requests = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCokenRequests(requests);
    });

    return () => {
      unsubscribeRecipes();
      unsubscribeCokenRequests();
    };
  }, []);

  const handleApproveRecipe = async (recipeId) => {
    try {
      const recipeRef = doc(db, 'sharedRecipes', recipeId);
      await updateDoc(recipeRef, { status: 'public' });
      alert('Рецепт одобрен и опубликован!');
    } catch (error) {
      console.error("Error approving recipe:", error);
    }
  };

  const handleDeleteRecipe = async (recipeId) => {
    try {
      const recipeRef = doc(db, 'sharedRecipes', recipeId);
      await deleteDoc(recipeRef);
      alert('Рецепт удален!');
    } catch (error) {
      console.error("Error deleting recipe:", error);
    }
  };

  const handleViewRecipe = (recipe) => {
    navigate(`/edit/${recipe.id}`, { state: { recipe } });
  };

  const handleApproveCokenRequest = async (requestId, userId, amount) => {
    try {
      const userRef = doc(db, 'users', userId);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        const currentBalance = userSnap.data().balance || 0;
        await updateDoc(userRef, { balance: currentBalance + amount });
      }
      const requestRef = doc(db, 'cokenRequests', requestId);
      await deleteDoc(requestRef);
      alert('Запрос на Cokens одобрен!');
    } catch (error) {
      console.error("Error approving coken request:", error);
    }
  };

  const handleDeleteCokenRequest = async (requestId) => {
    try {
      const requestRef = doc(db, 'cokenRequests', requestId);
      await deleteDoc(requestRef);
      alert('Запрос на Cokens удален!');
    } catch (error) {
      console.error("Error deleting coken request:", error);
    }
  };

  return (
    <div className='AwaitingModeration-container'>
      <h2>Модерация рецептов (Ждут)</h2>
      {draftRecipes.length === 0 ? (
        <p>Нет рецептов на модерации.</p>
      ) : (
        draftRecipes.map(recipe => (
          <div key={recipe.id} className="recipe-card-moder">
            <h3>{recipe.name}</h3>
            <p><strong>Автор:</strong> {recipe.authorName}</p>
            <p><strong>Цена:</strong> {recipe.price} Cokens</p>
            {recipe.slides && recipe.slides[0] && (
              <img src={recipe.slides[0]} alt={recipe.name} className="recipe-thumbnail-moder" />
            )}
            <div className="recipe-actions">
              <button onClick={() => handleViewRecipe(recipe)}>Посмотреть</button>
              <button onClick={() => handleApproveRecipe(recipe.id)}>Одобрить</button>
              <button onClick={() => handleDeleteRecipe(recipe.id)}>Удалить</button>
            </div>
          </div>
        ))
      )}

      <h2>Запросы на Cokens</h2>
      {cokenRequests.length === 0 ? (
        <p>Нет запросов на Cokens.</p>
      ) : (
        cokenRequests.map(request => (
          <div key={request.id} className="request-card-moder">
            <p><strong>Имя:</strong> {request.name}</p>
            <p><strong>Email:</strong> {request.email}</p>
            <p><strong>Запрошено Cokens:</strong> {request.amount}</p>
            <div className="request-actions">
              <button onClick={() => handleApproveCokenRequest(request.id, request.userId, request.amount)}>Одобрить</button>
              <button onClick={() => handleDeleteCokenRequest(request.id)}>Удалить</button>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default AwaitingModeration;
