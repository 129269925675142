import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth, confirmPasswordReset } from '../firebase';

function PasswordReset() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [oobCode, setOobCode] = useState('');
  const [loading, setLoading] = useState(true);
  
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('oobCode');
    
    if (code) {
      setOobCode(code);
      setLoading(false);
    } else {
      setMessage('Неверная ссылка для сброса пароля.');
      setLoading(false);
    }
  }, [location]);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Пароли не совпадают');
      return;
    }
    try {
      await confirmPasswordReset(auth, oobCode, password);
      setMessage('Пароль успешно изменен. Сейчас вы будете перенаправлены на страницу входа.');
      setTimeout(() => navigate('/login'), 3000);
    } catch (error) {
      setMessage('Ошибка при сбросе пароля: ' + error.message);
    }
  };

  if (loading) {
    return <div>Загрузка...</div>;
  }

  return (
    <div>
      <h2>Сброс пароля</h2>
      {oobCode ? (
        <form onSubmit={handleResetPassword}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Новый пароль"
            required
          />
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Подтвердите пароль"
            required
          />
          <button type="submit">Сбросить пароль</button>
        </form>
      ) : (
        <p>Неверная ссылка для сброса пароля.</p>
      )}
      {message && <p>{message}</p>}
    </div>
  );
}

export default PasswordReset;
