import React, { useState, useEffect } from 'react';
import './Modals.css'
function CategoryModal({ category, onClose, onSave, onDelete }) {
  const [name, setName] = useState('');
  const [deleteConfirm, setDeleteConfirm] = useState('');

  useEffect(() => {
    if (category) {
      setName(category.name);
    }
  }, [category]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(category ? { ...category, name } : { id: Date.now(), name });
  };

  const handleDelete = () => {
    if (deleteConfirm === category.name) {
      onDelete(category.id);
    } else {
      alert('Введенное название не совпадает с названием категории');
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>{category ? 'Редактировать категорию' : 'Добавить категорию'}</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Название категории"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <div className="modal-buttons">
            <button type="submit">Сохранить</button>
            <button type="button" onClick={onClose}>Закрыть</button>
          </div>
        </form>
        {category && (
          <div className="delete-section">
            <h3>Удаление категории</h3>
            <p>Для удаления введите название категории: <strong>{category.name}</strong></p>
            <input
              type="text"
              placeholder="Введите название категории"
              value={deleteConfirm}
              onChange={(e) => setDeleteConfirm(e.target.value)}
            />
            <button 
              className="delete-button"
              onClick={handleDelete}
              disabled={!deleteConfirm}
            >
<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" className="svg-inline--fa fa-trash " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z"></path></svg>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default CategoryModal;