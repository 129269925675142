import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import './Slider.css';
import imageCompression from 'browser-image-compression';

function Slider({ slides, setSlides }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  const changeSlide = (direction) => {
    let newSlide = currentSlide + direction;
    if (newSlide < 0) newSlide = slides.length - 1;
    if (newSlide >= slides.length) newSlide = 0;
    setCurrentSlide(newSlide);
  };

  const handlePhotoUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 0.15,
          maxWidthOrHeight: 400,
          useWebWorker: true,
        });

        const reader = new FileReader();
        reader.onload = (e) => {
          const newSlides = [...slides, e.target.result];
          setSlides(newSlides);
          setCurrentSlide(newSlides.length - 1);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error('Error compressing image:', error);
      }
    }
  };

  const removePhoto = () => {
    if (slides.length > 0) {
      const newSlides = slides.filter((_, index) => index !== currentSlide);
      setSlides(newSlides);
      if (currentSlide >= newSlides.length) {
        setCurrentSlide(newSlides.length - 1);
      }
    }
  };

  return (
    <div className="slider-container">
      <div className="slider">
        <div className="slider-wrapper">
          {slides.length === 0 ? (
            <div className="default-slide">Добавить фото</div>
          ) : (
            <div className="slide">
              <span className="slide-number">{currentSlide + 1} / {slides.length}</span>
              <img src={slides[currentSlide]} alt="Slide" />
            </div>
          )}
        </div>
        <button className="slider-btn prev" onClick={() => changeSlide(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button className="slider-btn next" onClick={() => changeSlide(1)}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
        <div className="slider-controls">
          <input
            type="file"
            id="photo-input"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handlePhotoUpload}
          />
          <button className="add-photo" onClick={() => document.getElementById('photo-input').click()}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
          <button className="remove-photo" onClick={removePhoto}>
            <FontAwesomeIcon icon={faMinus} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Slider;
