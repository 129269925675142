// Dashboard.js
import React, { useState, useEffect } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, setDoc, onSnapshot, collection, addDoc } from "firebase/firestore";
import { db } from '../firebase';
import ProfileModal from '../components/Modals/ProfileModal';
import './Dashboard.css';
import '../App.css';
import AwaitingModeration from './AwaitingModeration';
import { uploadToImgBB } from '../utils/imgbbUploader';

function Dashboard({ user, onLogout }) {
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState({});
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [error, setError] = useState(null);
  const [balance, setBalance] = useState(0);
  const [cells, setCells] = useState(0); // Добавлено для работы с ячейками
  const [isTopUpModalOpen, setIsTopUpModalOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [topUpAmount, setTopUpAmount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
  
        const unsubscribeSnapshot = onSnapshot(userDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const profileData = docSnapshot.data();
            setUserProfile(profileData);
            setBalance(profileData.balance || 0);
            setIsAdmin(profileData.role === "admin");
            localStorage.setItem('userProfile', JSON.stringify(profileData));
            localStorage.setItem('balance', profileData.balance || 0);
          } else {
            setUserProfile({});
            localStorage.removeItem('userProfile');
          }
        });
  
        return () => unsubscribeSnapshot();
      } else {
        setUserProfile({});
        localStorage.removeItem('userProfile');
      }
    });
  
    const savedProfile = localStorage.getItem('userProfile');
    if (savedProfile) {
      const parsedProfile = JSON.parse(savedProfile);
      setUserProfile(parsedProfile);
  
      const savedBalance = localStorage.getItem('balance');
      if (savedBalance !== null) {
        setBalance(parseInt(savedBalance, 10));
      }
    }
  
    return () => unsubscribeAuth();
  }, []);
  
  
  
  

  const handleLogout = () => {
    onLogout();
    navigate('/login');
    localStorage.removeItem('userProfile');
  };

  const handleSaveProfile = async (profileData) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      try {
        setLoading(true);

        let avatarUrl = profileData.avatar;

        if (profileData.avatarFile) {
          avatarUrl = await uploadToImgBB(profileData.avatarFile);
        }

        await setDoc(userDocRef, {
          avatar: avatarUrl,
          name: profileData.name,
          position: profileData.position,
          balance: balance,
          cells: cells, // Сохранение ячеек в Firestore
        }, { merge: true });

        const updatedProfile = { ...profileData, avatar: avatarUrl };
        setUserProfile(updatedProfile);
        setShowProfileModal(false);
        localStorage.setItem('userProfile', JSON.stringify(updatedProfile));
      } catch (error) {
        console.error("Ошибка при сохранении профиля:", error);
        setError("Ошибка при сохранении профиля: " + error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleTopUpRequest = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      try {
        await addDoc(collection(db, 'cokenRequests'), {
          userId: user.uid,
          name: userProfile.name,
          email: user.email,
          amount: topUpAmount,
        });
        setIsTopUpModalOpen(false);
        alert('Запрос на пополнение отправлен.');
      } catch (error) {
        console.error("Ошибка при отправке запроса на пополнение:", error);
        setError("Ошибка при отправке запроса на пополнение: " + error.message);
      }
    }
  };

  return (
    <div className="dashboard">
      <header className="dashboard-header">
        <div className="user-profile">
          <img 
            src={userProfile.avatar || '/default-avatar.png'} 
            alt="Фото профиля" 
            className="user-avatar"
          />
          <h2>{userProfile.name || 'Имя не указано'}</h2>
          <p>О себе: {userProfile.position || 'Должность не указана'}</p>
          <button 
            onClick={() => setShowProfileModal(true)} 
            className="button edit-profile-btn"
          >
            <i className="fas fa-pencil-alt">Edit</i> 
          </button>
          <div className="balance-display">
            <div className="balance-frame">
              <span className="balance-text">{balance} Cokens</span>
            </div>
            <button 
              onClick={() => setIsTopUpModalOpen(true)} 
              className="top-up-button"
            >
              Пополнить
            </button>
          </div>
          <button 
            onClick={handleLogout} 
            className="button logout-button"
          >
            Выйти
          </button>
          {loading && <div className="loading-spinner">Загрузка...</div>}
          {error && <div className="error-message">{error}</div>}
        </div>
      </header>

      <nav className="dashboard-nav">
        <Link to="/recipes" className="nav-link">
          <i className="fas fa-book"></i>
          <span>Мои</span>
        </Link>
        <Link to="/warehouse" className="nav-link" rel="noreferrer">
          <i className="fas fa-warehouse"></i>
          <span>Склад</span>
        </Link>
        <Link to="/shared-recipes" className="nav-link" rel="noreferrer">
          <i className="fas fa-share-alt"></i>
          <span>Общие</span>
        </Link>
      {isAdmin && (
        <Link to="/awaiting-moderation" className="nav-link">
          <i className="fas fa-clock"></i>
          <span>Ждут</span>
        </Link>
      )}
      </nav>
      <div className="dashboard-content">
        <Outlet />

      </div>
      <div className="beta-test-label">beta</div>
      {showProfileModal && (
        <ProfileModal
          user={userProfile}
          onClose={() => setShowProfileModal(false)}
          onSave={handleSaveProfile}
        />
      )}

      {isTopUpModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Запрос на пополнение Cokens</h2>
            <input
              type="number"
              value={topUpAmount}
              onChange={(e) => setTopUpAmount(parseInt(e.target.value, 10))}
              placeholder="Введите количество Cokens"
              className="top-up-input"
            />
            <button onClick={handleTopUpRequest} className="modal-action-button">
              Отправить запрос
            </button>
            <button onClick={() => setIsTopUpModalOpen(false)} className="modal-close-button">
              Закрыть
            </button>
          </div>
        </div>
      )}

      {error && <p className="error-message">{error}</p>}
    </div>
  );
}

export default Dashboard;
