import React, { useState, useEffect } from 'react';
import './Modals.css';

function IngredientModal({ ingredient, onClose, onSave, onDelete, warehouseIngredients = [] }) {
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    netto: '',
    waste: '', // процент горячего отхода
    coldWaste: '', // процент холодного отхода из склада
    brutto: '', // вес брутто
  });
  const [suggestions, setSuggestions] = useState([]);
  const [isInWarehouse, setIsInWarehouse] = useState(true);

  useEffect(() => {
    if (ingredient) {
      const warehouseIngredient = warehouseIngredients.find(item => item.name === ingredient.name);
      const coldWaste = warehouseIngredient?.coldWaste || 0;
      setFormData({
        ...ingredient,
        coldWaste,
        brutto: calculateBrutto(ingredient.netto, coldWaste),
      });
      setIsInWarehouse(!!warehouseIngredient);
    }
  }, [ingredient, warehouseIngredients]);

  const calculateBrutto = (netto, coldWaste) => {
    if (!netto || !coldWaste || coldWaste >= 100) return '';
    return (netto / (1 - coldWaste / 100)).toFixed(0);
  };

  const calculateReadyWeight = (netto, waste) => {
    if (!netto || !waste) return '';
    return Math.round(netto * (100 - waste) / 100);
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    const updatedFormData = { ...formData, [field]: value };

    // Если изменён вес брутто, пересчитываем процент холодного отхода
    if (field === 'brutto' && updatedFormData.brutto && updatedFormData.netto) {
      const netto = parseFloat(updatedFormData.netto);
      const brutto = parseFloat(updatedFormData.brutto);
      if (brutto > 0) {
        const coldWaste = 100 * (1 - (netto / brutto));
        updatedFormData.coldWaste = coldWaste.toFixed(2);
      }
    }

    // Если изменён процент холодного отхода, пересчитываем вес брутто
    if (field === 'coldWaste' && updatedFormData.coldWaste && updatedFormData.netto) {
      updatedFormData.brutto = calculateBrutto(updatedFormData.netto, updatedFormData.coldWaste);
    }

    // Если изменён вес нетто, пересчитываем вес брутто
    if (field === 'netto' && updatedFormData.netto && updatedFormData.coldWaste) {
      updatedFormData.brutto = calculateBrutto(updatedFormData.netto, updatedFormData.coldWaste);
    }

    setFormData(updatedFormData);

    // Автодополнение для названия ингредиента
    if (field === 'name') {
      const filteredSuggestions = (warehouseIngredients || []).filter(item =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
      setIsInWarehouse(filteredSuggestions.length > 0);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setFormData(prevData => ({
      ...prevData,
      name: suggestion.name,
      coldWaste: suggestion.coldWaste,
      waste: '',
      brutto: calculateBrutto(prevData.netto, suggestion.coldWaste),
    }));
    setSuggestions([]);
    setIsInWarehouse(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.name && formData.netto && formData.waste) {
      onSave({
        ...formData,
        id: formData.id || Date.now(),
        brutto: calculateBrutto(formData.netto, formData.coldWaste),
        readyWeight: calculateReadyWeight(formData.netto, formData.waste),
        isInWarehouse: isInWarehouse,
      });
    } else {
      alert('Пожалуйста, заполните все поля');
    }
  };

  const handleDelete = () => {
    if (ingredient && ingredient.id) {
      onDelete(ingredient.id);
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <input
              type="text"
              placeholder="Название ингредиента"
              value={formData.name}
              onChange={(e) => handleInputChange(e, 'name')}
              style={{ backgroundColor: isInWarehouse ? 'white' : '#f0f0f0' }}
            />
            {suggestions.length > 0 && (
              <ul className="suggestions">
                {suggestions.map(item => (
                  <li key={item.id} onClick={() => handleSuggestionClick(item)}>
                    {item.name}
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Первая строка: Брутто, Процент холодного отхода, Нетто */}
          <div className="input-row">
            <div className="input-group">
              <label>Брутто (г)</label>
              <input
                type="number"
                value={formData.brutto}
                onChange={(e) => handleInputChange(e, 'brutto')}
              />
            </div>
            <div className="input-group">
              <label>Холодный отход (%)</label>
              <input
                type="number"
                value={formData.coldWaste}
                onChange={(e) => handleInputChange(e, 'coldWaste')}
                className="cold-waste-input"
              />
            </div>
            <div className="input-group">
              <label>Нетто (г)</label>
              <input
                type="number"
                placeholder="Нетто (г)"
                value={formData.netto}
                onChange={(e) => handleInputChange(e, 'netto')}
              />
            </div>
          </div>

          {/* Вторая строка: Горячий отход, Выход */}
          <div className="input-row">
            <div className="input-group">
              <label>Горячий отход (%)</label>
              <input
                type="number"
                placeholder="Горячий отход"
                value={formData.waste}
                onChange={(e) => handleInputChange(e, 'waste')}
              />
            </div>
            <div className="input-group">
              <label>Выход (г)</label>
              <input
                type="text"
                placeholder="Выход (г)"
                value={calculateReadyWeight(formData.netto, formData.waste)}
                disabled
                style={{ backgroundColor: '#f0f0f0' }}
              />
            </div>
          </div>

          <div className="modal-buttons">
            <button type="submit">Сохранить</button>
            {ingredient && <button type="button" onClick={handleDelete}>Удалить</button>}
            <button type="button" onClick={onClose}>Закрыть</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default IngredientModal;
