// RecipeEditor.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Slider from '../components/Slider/Slider';
import IngredientsList from '../components/Ingredients/IngredientsList';
import Tabs from '../components/Tabs/Tabs';
import SaveContainer from '../components/SaveContainer/SaveContainer';
import PdfGenerator from '../components/PdfGenerator/PdfGenerator';
import { saveRecipe, getRecipes } from '../firebaseFunctions';
import { uploadToImgBB } from '../utils/imgbbUploader';
import { uploadToCloudinary } from '../utils/videoUploader';
import axios from 'axios';
import AlternativePdfGenerator from '../components/PdfGenerator/AlternativePdfGenerator';

function RecipeEditor() {
  const [slides, setSlides] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [steps, setSteps] = useState([]);
  const [recipe, setRecipe] = useState('');
  const [recipeName, setRecipeName] = useState('');
  const [originalRecipeName, setOriginalRecipeName] = useState('');
  const [videos, setVideos] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [mediaUrls, setMediaUrls] = useState({ slides: [], videos: [] });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadCancelTokens, setUploadCancelTokens] = useState([]);
  const [totalCalories, setTotalCalories] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    const savedState = JSON.parse(localStorage.getItem('recipeEditorState'));
    if (savedState) {
      setSlides(savedState.slides || []);
      setIngredients(savedState.ingredients || []);
      setSteps(savedState.steps || []);
      setRecipe(savedState.recipe || '');
      setRecipeName(savedState.recipeName || '');
      setVideos(savedState.videos || []);
      setVideoFiles(savedState.videoFiles || []);
      setTotalCalories(savedState.totalCalories || 0);
    }

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const currentState = {
      slides,
      ingredients,
      steps,
      recipe,
      recipeName,
      videos,
      videoFiles,
      totalCalories,
    };
    localStorage.setItem('recipeEditorState', JSON.stringify(currentState));
  }, [slides, ingredients, steps, recipe, recipeName, videos, videoFiles, totalCalories]);

  useEffect(() => {
    if (id !== 'new') {
      if (location.state?.recipe) {
        loadRecipeData(location.state.recipe);
      } else {
        loadRecipe(id);
      }
    }
  }, [id, location]);

  const loadRecipeData = (recipeData) => {
    setSlides(recipeData.slides || []);
    setIngredients(recipeData.ingredients || []);
    setSteps(recipeData.steps || []);
    setRecipe(recipeData.description || '');
    setRecipeName(recipeData.name || '');
    setOriginalRecipeName(recipeData.name || '');
    setVideos(Array.isArray(recipeData.videos) ? recipeData.videos : []);
    setMediaUrls({
      slides: recipeData.slides || [],
      videos: Array.isArray(recipeData.videos) ? recipeData.videos : [],
    });
    setTotalCalories(recipeData.totalCalories || 0);
    setSelectedCategory(recipeData.category || 'all')
  };

  const loadRecipe = async (recipeId) => {
    try {
      const recipes = await getRecipes();
      const currentRecipe = recipes.find((r) => r.id === recipeId);
      if (currentRecipe) {
        loadRecipeData(currentRecipe);
      }
    } catch (error) {
      console.error('Ошибка при загрузке рецепта:', error);
    }
  };

  const uploadToCloudinaryBatch = async (files) => {
    if (files.length === 0) return [];
    const newVideoUrls = [];
    const cancelTokens = [];

    try {
      for (const videoFile of files) {
        const source = axios.CancelToken.source();
        cancelTokens.push(source);
        const url = await uploadToCloudinary(
          videoFile,
          (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress((prev) => Math.min(prev + percentCompleted, 100));
          },
          source
        );
        newVideoUrls.push(url);
      }

      setUploadCancelTokens(cancelTokens);
      return newVideoUrls;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Загрузка видео отменена пользователем');
      } else {
        console.error('Ошибка при загрузке видео на Cloudinary:', error);
        throw error;
      }
    }
  };

  const saveRecipeToFirestore = async (selectedCategory) => {
    if (!recipeName.trim()) {
      alert('Пожалуйста, введите название рецепта');
      return;
    }
  
    const isNameChanged = recipeName !== originalRecipeName;
    const newRecipeId = id === 'new' || isNameChanged ? Date.now().toString() : id;
    setIsLoading(true);
  
    try {
      const slideUrls = await Promise.all(
        slides
          .filter((slide) => slide.startsWith('data:') || slide instanceof File)
          .map(async (slideData) => {
            if (slideData instanceof File) {
              return await uploadToImgBB(slideData);
            } else {
              const response = await fetch(slideData);
              const blob = await response.blob();
              return await uploadToImgBB(blob);
            }
          })
      );
  
      const existingSlideUrls = slides.filter((slide) => slide.startsWith('http'));
      const updatedSlideUrls = [...existingSlideUrls, ...slideUrls];
  
      const newVideoUrls = await uploadToCloudinaryBatch(videoFiles);
      const existingVideoUrls = Array.isArray(videos)
        ? videos.filter((url) => !url.startsWith('blob:'))
        : [];
      const updatedVideoUrls = [...existingVideoUrls, ...newVideoUrls];
  
      // Округление значений КБЖУ до одного знака после запятой
      const roundedIngredients = ingredients.map((ing) => ({
        ...ing,
        calories: parseFloat(ing.calories || 0).toFixed(1),
        proteins: parseFloat(ing.proteins || 0).toFixed(1),
        fats: parseFloat(ing.fats || 0).toFixed(1),
        carbs: parseFloat(ing.carbs || 0).toFixed(1),
      }));
  
      const recipeData = {
        id: newRecipeId,
        name: recipeName,
        ingredients: roundedIngredients, // Используем округленные значения
        steps,
        description: recipe,
        slides: updatedSlideUrls,
        videos: updatedVideoUrls,
        totalWeight: ingredients.reduce((sum, ing) => sum + parseFloat(ing.readyWeight || 0), 0),
        totalCost: ingredients.reduce((sum, ing) => sum + parseFloat(ing.cost || 0), 0),
        totalCalories: parseFloat(totalCalories).toFixed(1), // Округляем общие калории
        category: selectedCategory,
      };
  
      await saveRecipe(recipeData);
      alert(`Рецепт успешно сохранен с ID: ${newRecipeId}`);
  
      setVideos(updatedVideoUrls);
      setSlides(updatedSlideUrls);
      setVideoFiles([]);
      setOriginalRecipeName(recipeName);
      navigate('/recipes');
  
      localStorage.removeItem('recipeEditorState');
    } catch (error) {
      console.error('Ошибка при сохранении рецепта:', error);
      alert('Произошла ошибка при сохранении рецепта');
    } finally {
      setIsLoading(false);
      setUploadProgress(0);
      setUploadCancelTokens([]);
    }
  };
  

  const cancelUploads = () => {
    uploadCancelTokens.forEach((token) =>
      token.cancel('Загрузка видео отменена пользователем.')
    );
  };


  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    const currentDate = new Date().toLocaleDateString('ru-RU');

    printWindow.document.write(`
      <html>
        <head>
          <title>${recipeName}</title>
          <style>
        @page {
          size: A4 portrait;
          margin: 5mm;
        }

        body {
          font-family: 'Helvetica Neue', Arial, sans-serif;
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
          color: #000; /* Цвет текста по умолчанию */
          position: relative;
          box-sizing: border-box;
        }

        .main-image {
          object-position: left top;
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 1;
          border-radius: 4px;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
          object-fit: cover;
        }

        .overlay {
          position: absolute;
          top: 5mm;
          left: 5mm;
          right: 5mm;
          bottom: 5mm;
          padding: 2mm;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 2mm;
          z-index: 2;
        }

        .header {
          align-self: flex-start;
        }

        .header .title {
          font-size: 1.2em;
          font-weight: bold;
          text-shadow: 1px 1px 2px rgba(0,0,0,0.7);
          background: rgba(255, 255, 255, 0.3);
          padding: 0.2em 0.4em;
          border-radius: 2px;
        }

        .header .date {
          font-size: 0.7em;
          color: #ddd;
          margin-top: 0.1em;
          background: rgba(255, 255, 255, 0.3);
          padding: 0.1em 0.2em;
          border-radius: 2px;
          display: inline-block;
        }

        .main-content {
          display: flex;
          gap: 2mm;
          flex-grow: 1;
          align-items: flex-end;
        }

        .ingredients {
          width: 50%;
          color: #333;
          padding: 2mm;
          border-radius: 4px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 0.3);
          margin-top: auto;
        }

        .ingredients h2 {
          font-size: 1em;
          color: #ff5722;
          margin-bottom: 0.3em;
          background: rgba(255, 255, 255, 0.5);
          display: inline-block;
          padding: 0.3em 0.5em;
          border-radius: 2px;
        }

        .ingredients table {
          width: 100%;
          border-collapse: collapse;
          font-size: 0.8em;
          table-layout: fixed;
        }

        .ingredients th, .ingredients td {
          padding: 0.2em;
          text-align: left;
          border-bottom: 1px solid #ddd;
          background: rgba(255, 255, 255, 0.3);
          border-radius: 2px;
        }

        .ingredients th {
          color: #333;
          background: rgba(255, 255, 255, 0.5);
        }

        .ingredients tbody tr:last-child td {
          border-top: 2px solid #ccc;
          font-weight: bold;
        }

        .steps {
          width: 50%;
          display: flex;
          flex-wrap: wrap;
          gap: 2mm;
          margin-top: auto;
        }

        .step {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 32%;
          padding: 1mm;
          border-radius: 4px;
          box-sizing: border-box;
          position: relative;
        }

        .step .image-container {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .step img {
          width: 100%;
          height: auto;
          max-height: 60px;
          object-fit: cover;
          border-radius: 4px;
          margin-bottom: 0.5em;
        }

        .step .step-number {
          position: absolute;
          top: -0.5em;
          left: -0.5em;
          background: rgba(255, 255, 255, 0.7);
          color: #ff5722;
          font-weight: bold;
          font-size: 0.8em;
          padding: 0.2em 0.4em;
          border-radius: 50%;
          width: 1.5em;
          height: 1.5em;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .step .step-number.no-image {
          position: static;
          background: rgba(255, 255, 255, 0.7);
          margin-bottom: 0.5em;
        }

        .step div {
          font-size: 0.9em;
          background: rgba(28, 3, 3, 0.64);
          border-radius: 4px;
          padding: 0.5em;
          margin-top: 0.5em;
          z-index: 41;
        }

        .footer {
          text-align: center;
          font-size: 0.6em;
          color: #ccc;
        }

        .print-button {
          display: none;
        }

        @media print {
            body {
          font-family: 'Helvetica Neue', Arial, sans-serif;
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
          color: #000; /* Цвет текста по умолчанию */
          position: relative;
          box-sizing: border-box;
        }

        .main-image {
          object-position: left top;
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 1;
          border-radius: 4px;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
          object-fit: cover;
        }

        .overlay {
          position: absolute;
          top: 5mm;
          left: 5mm;
          right: 5mm;
          bottom: 5mm;
          background: rgba(0, 0, 0, 0.3);
          padding: 2mm;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 2mm;
          z-index: 2;
        }

        .header {
          align-self: flex-start;
        }

        .header .title {
          font-size: 1.2em;
          font-weight: bold;
          text-shadow: 1px 1px 2px rgba(0,0,0,0.7);
          background: rgba(255, 255, 255, 0.3);
          padding: 0.2em 0.4em;
          border-radius: 2px;
        }

        .header .date {
          font-size: 0.7em;
          color: #ddd;
          margin-top: 0.1em;
          background: rgba(255, 255, 255, 0.3);
          padding: 0.1em 0.2em;
          border-radius: 2px;
          display: inline-block;
        }

        .main-content {
          display: flex;
          gap: 2mm;
          flex-grow: 1;
          align-items: flex-end;
        }

        .ingredients {
          width: 50%;
          color: #333;
          padding: 2mm;
          border-radius: 4px;
          box-sizing: border-box;
          background: rgba(255, 255, 255, 0.3);
          margin-top: auto;
        }

        .ingredients h2 {
          font-size: 1em;
          color: #ff5722;
          margin-bottom: 0.3em;
          background: rgba(255, 255, 255, 0.5);
          display: inline-block;
          padding: 0.3em 0.5em;
          border-radius: 2px;
        }

        .ingredients table {
          width: 100%;
          border-collapse: collapse;
          font-size: 0.8em;
          table-layout: fixed;
        }

        .ingredients th, .ingredients td {
          padding: 0.2em;
          text-align: left;
          border-bottom: 1px solid #ddd;
          background: rgba(255, 255, 255, 0.3);
          border-radius: 2px;
        }

        .ingredients th {
          color: #333;
          background: rgba(255, 255, 255, 0.5);
        }

        .ingredients tbody tr:last-child td {
          border-top: 2px solid #ccc;
          font-weight: bold;
        }

        .steps {
          width: 50%;
          display: flex;
          flex-wrap: wrap;
          gap: 2mm;
          margin-top: auto;
        }

        .step {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 32%;
          padding: 1mm;
          border-radius: 4px;
          box-sizing: border-box;
          position: relative;
        }

        .step .image-container {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .step img {
          width: 100%;
          height: auto;
          max-height: 60px;
          object-fit: cover;
          border-radius: 4px;
          margin-bottom: 0.5em;
        }

        .step .step-number {
          position: absolute;
          top: -0.5em;
          left: -0.5em;
          background: rgba(255, 255, 255, 0.7);
          color: #ff5722;
          font-weight: bold;
          font-size: 0.8em;
          padding: 0.2em 0.4em;
          border-radius: 50%;
          width: 1.5em;
          height: 1.5em;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .step .step-number.no-image {
          position: static;
          background: rgba(255, 255, 255, 0.7);
          margin-bottom: 0.5em;
        }

        .step div {
          font-size: 0.9em;
          background: rgba(28, 3, 3, 0.64);
          border-radius: 4px;
          padding: 0.5em;
          margin-top: 0.5em;
          z-index: 41;
        }

        .footer {
          text-align: center;
          font-size: 0.6em;
          color: #ccc;
        }

        .print-button {
          display: none;
        }


          @page {
            margin: 5mm;
          }
        }
      </style>
        </head>
        <body>
          <!-- Главная картинка -->
          <img src="${mediaUrls.slides[0]}" alt="Главное изображение" class="main-image" />

          <div class="overlay">
            <!-- Заголовок -->
            <div class="header">
              <div class="title">${recipeName}</div>
              <div class="date">Дата: ${currentDate}</div>
            </div>

            <!-- Основной Контент -->
            <div class="main-content">
              <!-- Левая колонка: Ингредиенты -->
              <div class="ingredients">
                <h2>Ингредиенты</h2>
                <div class="table-container" id="tableContainer">
                  <table id="resizableTable">
                    <thead>
                      <tr>
                        <th>Ингредиент</th>
                        <th></th>
                        <th>Брутто</th>
                        <th>Нетто</th>
                      </tr>
                    </thead>
                    <tbody>
                      ${ingredients.map(ing => `
                        <tr>
                          <td>${ing.name}</td>
                          <td>${ing.quantity || ''} ${ing.unit || ''}</td>
                          <td>${Number(ing.brutto || 0).toFixed(1)}г</td>
                          <td>${Number(ing.netto || 0).toFixed(1)}г</td>
                        </tr>
                      `).join('')}
                      <tr>
                        <td colspan="3">Выход:</td>
                        <td>${Number(
                          ingredients.reduce((sum, ing) => sum + parseFloat(ing.readyWeight || 0), 0)
                        ).toFixed(1)}г</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <!-- Правая колонка: Шаги приготовления -->
      
            </div>

            <!-- Футер -->
            <div class="footer">
              Приятного аппетита!
            </div>
          </div>
          <button onclick="window.print()" class="print-button">Печать</button>

          <script>
            let isDragging = false;
            let startX, startY, initialX, initialY;

            const tableContainer = document.getElementById('tableContainer');
            const resizableTable = document.getElementById('resizableTable');

            // Функция для перемещения таблицы
            tableContainer.addEventListener('mousedown', (e) => {
              isDragging = true;
              startX = e.clientX;
              startY = e.clientY;
              initialX = tableContainer.offsetLeft;
              initialY = tableContainer.offsetTop;
            });

            document.addEventListener('mousemove', (e) => {
              if (isDragging) {
                const dx = e.clientX - startX;
                const dy = e.clientY - startY;
                tableContainer.style.left = initialX + dx + 'px';
                tableContainer.style.top = initialY + dy + 'px';
              }
            });

            document.addEventListener('mouseup', () => {
              isDragging = false;
            });

            // Функция для изменения размера таблицы
            let isResizing = false;
            let startWidth, startHeight;

            resizableTable.addEventListener('mousedown', (e) => {
              if (e.offsetX > resizableTable.offsetWidth - 10 && e.offsetY > resizableTable.offsetHeight - 10) {
                isResizing = true;
                startWidth = resizableTable.offsetWidth;
                startHeight = resizableTable.offsetHeight;
                startX = e.clientX;
                startY = e.clientY;
              }
            });

            document.addEventListener('mousemove', (e) => {
              if (isResizing) {
                const dx = e.clientX - startX;
                const dy = e.clientY - startY;
                resizableTable.style.width = startWidth + dx + 'px';
                resizableTable.style.height = startHeight + dy + 'px';
              }
            });

            document.addEventListener('mouseup', () => {
              isResizing = false;
            });
          </script>
        </body>
      </html>
    `);

    printWindow.document.close();
};

  


  return (
    <div className="editor">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          {uploadProgress > 0 && uploadProgress < 100 && (
            <div className="upload-progress">
              <div className="progress-bar">
                <div
                  className="progress-fill"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
              <span>Загрузка видео: {uploadProgress}%</span>
              <button className="cancel-button" onClick={cancelUploads}>
                Отмена загрузки
              </button>
            </div>
          )}
        </div>
      )}
      <Slider
        slides={slides}
        setSlides={setSlides}
        mediaUrls={mediaUrls}
        setMediaUrls={setMediaUrls}
      />
      <IngredientsList
        ingredients={ingredients}
        setIngredients={setIngredients}
        onTotalCaloriesChange={setTotalCalories}
      />
      <Tabs
        steps={steps}
        setSteps={setSteps}
        recipe={recipe}
        setRecipe={setRecipe}
        videos={videos}
        setVideos={setVideos}
        videoFiles={videoFiles}
        setVideoFiles={setVideoFiles}
        mediaUrls={mediaUrls}
        setMediaUrls={setMediaUrls}
      />
      <SaveContainer
        recipeName={recipeName}
        setRecipeName={setRecipeName}
        onSave={saveRecipeToFirestore}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}

      />
      <PdfGenerator
        recipe={{
          name: recipeName,
          ingredients,
          steps,
          description: recipe,
        }}
      />
      <AlternativePdfGenerator
        recipe={{
          name: recipeName,
          ingredients,
          steps,
          description: recipe,
          imageUrls: mediaUrls.slides,
        }}
      />
      <button onClick={handlePrint} style={{ marginTop: '20px', padding: '10px 20px' }}>
        Печать рецепта
      </button>
    </div>
  );
}

export default RecipeEditor;
