import React, { useState, useEffect } from 'react';
import './Modals.css';

function WarehouseIngredientModal({ ingredient, onClose, onSave, onDelete }) {
  const [formData, setFormData] = useState({
    name: '',
    price: 0, // Значение по умолчанию
    coldWaste: 0, // Значение по умолчанию
    calories: 0, // Значение по умолчанию
    proteins: 0, // Значение по умолчанию
    fats: 0,     // Значение по умолчанию
    carbs: 0     // Значение по умолчанию
  });

  useEffect(() => {
    if (ingredient) {
      setFormData({
        name: ingredient.name || '',
        price: ingredient.price || '',
        coldWaste: ingredient.coldWaste || 0,
        calories: ingredient.calories || 0,
        proteins: ingredient.proteins || 0,
        fats: ingredient.fats || 0,
        carbs: ingredient.carbs || 0
      });
    }
  }, [ingredient]);

  const handleInputChange = (e, field) => {
    const value = e.target.value === '' ? '' : parseFloat(e.target.value);
    setFormData({ ...formData, [field]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({
      ...formData,
      id: ingredient ? ingredient.id : Date.now()
    });
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>{ingredient ? 'Редактировать ингредиент' : 'Добавить ингредиент'}</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Название ингредиента
            <input
              type="text"
              value={formData.name}
              onChange={(e) => setFormData({...formData, name: e.target.value})}
              required
            />
          </label>
          <label>
            Цена за 1 кг
            <input
              type="number"
              value={formData.price}
              onChange={(e) => handleInputChange(e, 'price')}
              required
            />
          </label>
          <label>
            % отхода при холодной обработке
            <input
              type="number"
              value={formData.coldWaste}
              onChange={(e) => handleInputChange(e, 'coldWaste')}
              required
            />
          </label>
          <label>
            Калории (на 100 г)
            <input
              type="number"
              value={formData.calories}
              onChange={(e) => handleInputChange(e, 'calories')}
              required
            />
          </label>
          <label>
            Белки (на 100 г)
            <input
              type="number"
              value={formData.proteins}
              onChange={(e) => handleInputChange(e, 'proteins')}
              required
            />
          </label>
          <label>
            Жиры (на 100 г)
            <input
              type="number"
              value={formData.fats}
              onChange={(e) => handleInputChange(e, 'fats')}
              required
            />
          </label>
          <label>
            Углеводы (на 100 г)
            <input
              type="number"
              value={formData.carbs}
              onChange={(e) => handleInputChange(e, 'carbs')}
              required
            />
          </label>
          <div className="modal-buttons">
            <button type="submit">Сохранить</button>
            {ingredient && <button type="button" onClick={() => onDelete(ingredient.id)}>Удалить</button>}
            <button type="button" onClick={onClose}>Закрыть</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default WarehouseIngredientModal;
