import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { saveRecipe, deleteRecipe, shareRecipe } from '../firebaseFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faDollarSign, faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { auth } from '../firebase';
import './RecipesList.css';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import MultiRecipeSelectionModal from './MultiRecipeSelectionModal';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import FilterBar from './FilterBar';

function RecipesList() {
  const [recipes, setRecipes] = useState([]); // Состояние для хранения рецептов
  const [filteredRecipes, setFilteredRecipes] = useState([]); // Состояние для отфильтрованных рецептов
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [selectedRecipeToShare, setSelectedRecipeToShare] = useState(null);
  const [price, setPrice] = useState(0);
  const [priceError, setPriceError] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Состояние загрузки
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [recipeToDownload, setRecipeToDownload] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(user => {
      if (user) {
        // Запрос с фильтрацией по authorId
        const q = query(collection(db, 'recipes'), where('authorId', '==', user.uid));

        // Подписка на изменения в коллекции
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const recipesList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setRecipes(recipesList); // Обновляем состояние recipes
          setFilteredRecipes(recipesList); // Обновляем состояние filteredRecipes
          setIsLoading(false); // Загрузка завершена
        });

        // Отписка при размонтировании компонента или изменении пользователя
        return () => {
          unsubscribe();
        };
      } else {
        setRecipes([]);
        setFilteredRecipes([]);
        setIsLoading(false); // Если пользователь не авторизован, загрузка завершена
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  // Обработчик удаления рецепта
  const handleDeleteRecipe = async (id) => {
    if (!window.confirm('Вы уверены, что хотите удалить этот рецепт?')) return;
    try {
      await deleteRecipe(id);
      const updatedRecipes = recipes.filter(recipe => recipe.id !== id);
      setRecipes(updatedRecipes); // Обновляем состояние recipes
      setFilteredRecipes(updatedRecipes); // Обновляем состояние filteredRecipes
      alert('Рецепт успешно удален.');
    } catch (error) {
      console.error("Ошибка при удалении рецепта:", error);
      alert('Произошла ошибка при удалении рецепта.');
    }
  };


  // Скачивание рецепта в формате Excel
  const handleDownloadExcel = (recipe) => {
    const worksheet = XLSX.utils.json_to_sheet([{
      name: recipe.name,
      description: recipe.description,
      category: recipe.category,
      totalWeight: recipe.totalWeight,
      totalCost: recipe.totalCost,
      totalCalories: recipe.totalCalories,
      ingredients: JSON.stringify(recipe.ingredients),
      steps: JSON.stringify(recipe.steps),
      videos: JSON.stringify(recipe.videos),
      slidesCount: recipe.slides.length,
    }]);

    const slidesWorksheet = XLSX.utils.json_to_sheet(
      recipe.slides.flatMap((slide, index) => {
        const parts = [];
        for (let i = 0; i < slide.length; i += 32000) {
          parts.push({
            index: index + 1,
            part: Math.floor(i / 32000) + 1,
            base64: slide.slice(i, i + 32000),
          });
        }
        return parts;
      }),
      { header: ['index', 'part', 'base64'] }
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Рецепт');
    XLSX.utils.book_append_sheet(workbook, slidesWorksheet, 'Слайды');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, `${recipe.name}.xlsx`);
  };

  // Скачивание рецепта в формате JSON
  const handleDownloadJSON = (recipe) => {
    const dataStr = JSON.stringify(recipe, null, 2);
    const blob = new Blob([dataStr], { type: 'application/json' });
    saveAs(blob, `${recipe.name}.json`);
  };

  // Открытие модального окна скачивания
  const handleDownloadRecipe = (recipe) => {
    setRecipeToDownload(recipe);
    setIsDownloadModalOpen(true);
  };

  // Подтверждение скачивания
  const confirmDownload = (format) => {
    if (recipeToDownload) {
      if (format === 'xlsx') {
        handleDownloadExcel(recipeToDownload);
      } else if (format === 'json') {
        handleDownloadJSON(recipeToDownload);
      }
      setIsDownloadModalOpen(false);
      setRecipeToDownload(null);
    }
  };

  // Загрузка рецепта из файла
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsLoading(true);

    const reader = new FileReader();

    reader.onload = (evt) => {
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (fileExtension === 'xlsx' || fileExtension === 'xls') {
        const bstr = evt.target.result;
        const workbook = XLSX.read(bstr, { type: 'binary' });

        const recipeWorksheet = workbook.Sheets['Рецепт'];
        const recipeData = XLSX.utils.sheet_to_json(recipeWorksheet)[0];

        const slidesWorksheet = workbook.Sheets['Слайды'];
        const slidesData = XLSX.utils.sheet_to_json(slidesWorksheet);

        const slides = slidesData.reduce((acc, row) => {
          if (!acc[row.index - 1]) {
            acc[row.index - 1] = '';
          }
          acc[row.index - 1] += row.base64;
          return acc;
        }, []);

        const newRecipe = {
          id: Date.now().toString(),
          name: recipeData.name,
          description: recipeData.description,
          category: recipeData.category,
          totalWeight: recipeData.totalWeight,
          totalCost: recipeData.totalCost,
          totalCalories: recipeData.totalCalories,
          ingredients: JSON.parse(recipeData.ingredients),
          steps: JSON.parse(recipeData.steps),
          videos: JSON.parse(recipeData.videos),
          slides: slides,
          userId: auth.currentUser.uid, // Добавляем userId текущего пользователя
        };

        saveRecipe(newRecipe).then(() => {
          setIsLoading(false);
          alert('Рецепт успешно загружен.');
        }).catch(error => {
          console.error("Ошибка при сохранении рецепта в Firestore:", error);
          alert('Произошла ошибка при сохранении рецепта.');
          setIsLoading(false);
        });
      }

      else if (fileExtension === 'json') {
        try {
          const json = JSON.parse(evt.target.result);
          const requiredFields = ['name', 'description', 'category', 'totalWeight', 'totalCost', 'totalCalories', 'ingredients', 'steps', 'videos', 'slides'];
          const hasAllFields = requiredFields.every(field => field in json);
          if (!hasAllFields) {
            throw new Error('Некоторые необходимые поля отсутствуют в JSON файле.');
          }

          const newRecipe = {
            id: Date.now().toString(),
            ...json,
            userId: auth.currentUser.uid, // Добавляем userId текущего пользователя
          };

          saveRecipe(newRecipe).then(() => {
            setIsLoading(false);
            alert('Рецепт успешно загружен.');
          }).catch(error => {
            console.error("Ошибка при сохранении рецепта в Firestore:", error);
            alert('Произошла ошибка при сохранении рецепта.');
            setIsLoading(false);
          });
        } catch (error) {
          console.error("Ошибка при парсинге JSON файла:", error);
          alert('Ошибка при загрузке JSON файла: ' + error.message);
          setIsLoading(false);
        }
      } else {
        alert('Неподдерживаемый формат файла. Пожалуйста, загрузите Excel или JSON файл.');
        setIsLoading(false);
      }
    };

    const fileType = file.name.split('.').pop().toLowerCase();
    if (fileType === 'xlsx' || fileType === 'xls') {
      reader.readAsBinaryString(file);
    } else if (fileType === 'json') {
      reader.readAsText(file);
    } else {
      alert('Неподдерживаемый формат файла. Пожалуйста, загрузите Excel или JSON файл.');
      setIsLoading(false);
    }
  };

  // Открытие модального окна обмена рецептом
  const handleShareRecipe = async (recipe) => {
    setSelectedRecipeToShare(recipe);
    setIsShareModalOpen(true);
  };

  // Подтверждение обмена рецептом
  const handleConfirmShare = async () => {
    if (selectedRecipeToShare) {
      const updatedRecipe = {
        ...selectedRecipeToShare,
        price: price,
        status: 'draft',
      };
      try {
        await shareRecipe(updatedRecipe);
        alert('Рецепт успешно отправлен на рассмотрение.');
        setIsShareModalOpen(false);
        setPrice(0);
      } catch (error) {
        console.error('Ошибка при отправке рецепта:', error);
        alert('Произошла ошибка при отправке рецепта.');
      }
    }
  };

  return (
    <div className="my-recipes-container">
      {/* Индикатор загрузки */}
      {isLoading ? (
        <div className="my-loading-bar">
          <p>Загрузка рецептов...</p>
        </div>
      ) : (
        <>
          {/* Кнопка для генерации отчёта */}
          <button 
            className="generate-report-button" 
            onClick={() => setIsReportModalOpen(true)}
            title="Сгенерировать отчет"
          >
            Отчет
          </button>
          
          {/* Фильтр рецептов */}
          <FilterBar
            onFilter={(filters) => {
              const { category, calories, author, sortBy } = filters;
              let filtered = [...recipes];

              // Фильтрация по категории
              if (category !== 'all') {
                filtered = filtered.filter(recipe => recipe.category === category);
              }

              // Фильтрация по калориям
              if (calories !== undefined && calories !== null) {
                const caloriesNumber = Number(calories);
                if (!isNaN(caloriesNumber)) {
                  filtered = filtered.filter(recipe => recipe.totalCalories <= caloriesNumber);
                }
              }

              // Фильтрация по автору или названию рецепта
              if (author) {
                const searchText = author.toLowerCase();
                filtered = filtered.filter(recipe => 
                  (recipe.authorName && recipe.authorName.toLowerCase().includes(searchText)) ||
                  (recipe.name && recipe.name.toLowerCase().includes(searchText))
                );
              }

              // Сортировка
              if (sortBy) {
                if (sortBy === 'new') {
                  filtered.sort((a, b) => new Date(b.date) - new Date(a.date));
                } else if (sortBy === 'likes') {
                  filtered.sort((a, b) => (b.likes || 0) - (a.likes || 0));
                } else if (sortBy === 'comments') {
                  filtered.sort((a, b) => (b.comments?.length || 0) - (a.comments?.length || 0));
                }
              }

              setFilteredRecipes(filtered);
            }}
            showSortByNew={true}
          />
          
          {/* Сетка рецептов */}
          <div className="my-recipes-grid">
            {filteredRecipes.map((recipe) => (
              <div
                key={recipe.id}
                className='my-recipe-card'
                onClick={() => navigate(`/edit/${recipe.id}`)}
                title="Редактировать рецепт"
              >
                <img
                  src={recipe.slides && recipe.slides[0] ? recipe.slides[0] : '/default-image.jpg'}
                  alt={recipe.name}
                  className="my-recipe-image"
                />
                <div className="my-recipe-content">
                  <h2 className="my-recipe-name">{recipe.name}</h2>
                  <div className="my-recipe-details">
                    <div>{recipe.totalWeight} гр</div>
                    <div>{recipe.totalCost} руб</div>
                    <div>{recipe.totalCalories || 0} ккал</div>
                  </div>
                  <div className="my-recipe-actions">
                    {/* Кнопка удаления рецепта */}
                    <button 
                      onClick={(e) => { 
                        e.stopPropagation(); 
                        handleDeleteRecipe(recipe.id); 
                      }} 
                      className="my-action-button"
                      title="Удалить рецепт"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>

                    {/* Кнопка редактирования рецепта */}
                    <Link 
                      to={`/edit/${recipe.id}`} 
                      className="my-recipe-actions-action" 
                      role="button" 
                      title="Редактировать рецепт"
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Link>

                    {/* Кнопка обмена рецептом */}
                    <button 
                      onClick={(e) => { 
                        e.stopPropagation(); 
                        handleShareRecipe(recipe); 
                      }} 
                      className="my-action-button"
                      title="Отправить рецепт на обмен"
                    >
                      <FontAwesomeIcon icon={faDollarSign} />
                    </button>

                    {/* Кнопка скачивания рецепта */}
                    <button 
                      onClick={(e) => { 
                        e.stopPropagation(); 
                        handleDownloadRecipe(recipe); 
                      }} 
                      className="my-action-button"
                      title="Скачать рецепт"
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </button>
                  </div>
                </div>
              </div>
            ))}

            {/* Ячейка для добавления нового рецепта */}
            <div 
              className="my-empty-cell" 
              onClick={() => navigate('/edit/new')}
              title="Добавить новый рецепт"
            >
              <div className="my-empty-cell-content">+</div>
            </div>
          </div>
        </>
      )}

      {/* Секция загрузки рецептов */}
      <div className="my-upload-section">
        <input 
          type="file" 
          accept=".xlsx, .xls, .json" 
          onChange={handleFileUpload} 
          style={{ display: 'none' }} 
          id="file-upload" 
        />
        <label htmlFor="file-upload" className="my-upload-button" title="Загрузить рецепты">
          <FontAwesomeIcon icon={faUpload} /> Загрузить рецепты
        </label>
      </div>

      {/* Модальное окно выбора формата скачивания */}
      {isDownloadModalOpen && (
        <div className="my-modal-overlay">
          <div className="my-modal-content">
            <h2>Выберите формат для скачивания</h2>
            <div className="my-modal-buttons">
              <button onClick={() => confirmDownload('xlsx')} className="my-modal-button">
                Excel (XLSX)
              </button>
              <button onClick={() => confirmDownload('json')} className="my-modal-button">
                JSON
              </button>
            </div>
            <button 
              className="my-modal-close-button" 
              onClick={() => setIsDownloadModalOpen(false)}
              title="Закрыть"
            >
              Закрыть
            </button>
          </div>
        </div>
      )}

      {/* Модальное окно обмена рецептом */}
      {isShareModalOpen && selectedRecipeToShare && (
        <div className="my-modal-overlay">
          <div className="my-modal-content">
            <h2>Установить цену рецепта</h2>
            <input
              type="number"
              value={price}
              onChange={(e) => {
                const value = e.target.value;
                const parsedValue = parseInt(value, 10);
                if (isNaN(parsedValue) || parsedValue < 0) {
                  setPrice(0);
                } else if (parsedValue > 100) {
                  setPrice(100);
                } else {
                  setPrice(parsedValue);
                }
                setPriceError('');
              }}
              placeholder="Введите цену (0-100)"
              className="my-price-input"
            />
            {priceError && <p className="my-error-message">{priceError}</p>}
            <div className="my-modal-actions">
              <button 
                onClick={handleConfirmShare} 
                className="my-modal-button"
                title="Отправить рецепт на обмен"
              >
                Отправить
              </button>
              <button 
                onClick={() => setIsShareModalOpen(false)} 
                className="my-modal-button"
                title="Закрыть"
              >
                Закрыть
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Модальное окно выбора нескольких рецептов для отчёта */}
      {isReportModalOpen && (
        <MultiRecipeSelectionModal
          recipes={recipes}
          onClose={() => setIsReportModalOpen(false)}
        />
      )}
    </div>
  );
}

export default RecipesList;
