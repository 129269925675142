import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, onSnapshot, doc, updateDoc, where, getDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faThumbsUp, faThumbsDown, faComments, faTrash } from '@fortawesome/free-solid-svg-icons';
import RecipeModal from '../components/RecipeModal';
import './SharedRecipesList.css';
import { saveRecipe, updateUserBalance, deleteSharedRecipe } from '../firebaseFunctions';
import FilterBar from './FilterBar';

function SharedRecipesList() {
  const [sharedRecipes, setSharedRecipes] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
  const [selectedRecipeToBuy, setSelectedRecipeToBuy] = useState(null);
  const [purchasedRecipes, setPurchasedRecipes] = useState([]);
  const [filters, setFilters] = useState({
    category: 'all',
    calories: '',
    author: '',
    sortBy: 'new',
  });
  const navigate = useNavigate();

  // Fetch current user and their purchased recipes
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);

      const unsubscribeUser = onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          setCurrentUser(userData);
          setPurchasedRecipes(userData.purchasedRecipes || []);
        } else {
          setCurrentUser({ name: 'Бэтмэн', avatar: '/default-avatar.jpg' });
        }
        setIsUserLoaded(true);
      });

      return () => unsubscribeUser();
    } else {
      setIsUserLoaded(true);
    }
  }, []);

  // Fetch shared recipes
  useEffect(() => {
    const q = query(collection(db, 'sharedRecipes'), where('status', '==', 'public'));

    const unsubscribeRecipes = onSnapshot(q, (snapshot) => {
      const sharedRecipesList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSharedRecipes(sharedRecipesList);
    });

    return () => unsubscribeRecipes();
  }, []);

  // Apply filters whenever sharedRecipes or filters change
  useEffect(() => {
    handleFilter(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedRecipes, filters]);

  // Handle like action
  const handleLike = async (id) => {
    try {
      const sharedRecipeRef = doc(db, 'sharedRecipes', id);
      const sharedRecipeSnap = await getDoc(sharedRecipeRef);
      if (sharedRecipeSnap.exists()) {
        const recipeData = sharedRecipeSnap.data();
        await updateDoc(sharedRecipeRef, {
          likes: (recipeData.likes || 0) + 1,
        });
      }
    } catch (error) {
      console.error("Error updating likes:", error);
    }
  };

  // Handle dislike action
  const handleDislike = async (id) => {
    try {
      const sharedRecipeRef = doc(db, 'sharedRecipes', id);
      const sharedRecipeSnap = await getDoc(sharedRecipeRef);
      if (sharedRecipeSnap.exists()) {
        const recipeData = sharedRecipeSnap.data();
        await updateDoc(sharedRecipeRef, {
          dislikes: (recipeData.dislikes || 0) + 1,
        });
      }
    } catch (error) {
      console.error("Error updating dislikes:", error);
    }
  };

  // Handle view action
  const handleView = async (id) => {
    try {
      const sharedRecipeRef = doc(db, 'sharedRecipes', id);
      const sharedRecipeSnap = await getDoc(sharedRecipeRef);
      if (sharedRecipeSnap.exists()) {
        const recipeData = sharedRecipeSnap.data();
        navigate(`/edit/${id}`, { state: { recipe: recipeData } });
      } else {
        console.error("Recipe does not exist in Firestore");
      }
    } catch (error) {
      console.error("Error fetching shared recipe:", error);
    }
  };

  // Handle saving a recipe
  const handleSaveRecipe = async (recipe) => {
    setIsLoading(true);
    try {
      const newRecipeData = {
        id: Date.now().toString(),
        name: recipe.name,
        ingredients: recipe.ingredients,
        steps: recipe.steps,
        description: recipe.description,
        slides: recipe.slides,
        videos: recipe.videos,
        totalWeight: recipe.totalWeight,
        totalCost: recipe.totalCost,
        totalCalories: recipe.totalCalories,
        category: recipe.category,
      };

      await saveRecipe(newRecipeData);
      alert(`Рецепт "${recipe.name}" успешно сохранен!`);
    } catch (error) {
      console.error('Ошибка при сохранении рецепта:', error);
      alert('Произошла ошибка при сохранении рецепта');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle buying a recipe
  const handleBuyRecipe = async (recipe) => {
    if (recipe.price > 0) {
      setSelectedRecipeToBuy(recipe);
      setIsBuyModalOpen(true);
    } else {
      handleSaveRecipe(recipe);
    }
  };

  // Handle filtering recipes based on current filter state
  const handleFilter = (currentFilters) => {
    const { category, calories, author, sortBy } = currentFilters;
    let filtered = [...sharedRecipes];

    // Filter by category
    if (category !== 'all') {
      filtered = filtered.filter((recipe) => recipe.category === category);
    }

    // Filter by calories
    if (calories) {
      const caloriesNumber = Number(calories);
      if (!isNaN(caloriesNumber)) {
        filtered = filtered.filter((recipe) => recipe.totalCalories <= caloriesNumber);
      }
    }

    // Filter by author or recipe name
    if (author.trim() !== '') {
      const searchText = author.toLowerCase();
      filtered = filtered.filter(
        (recipe) =>
          (recipe.authorName && recipe.authorName.toLowerCase().includes(searchText)) ||
          (recipe.name && recipe.name.toLowerCase().includes(searchText))
      );
    }

    // Sort the filtered recipes
    if (sortBy === 'new') {
      filtered.sort((a, b) => new Date(b.date) - new Date(a.date));
    } else if (sortBy === 'likes') {
      filtered.sort((a, b) => (b.likes || 0) - (a.likes || 0));
    } else if (sortBy === 'comments') {
      filtered.sort((a, b) => (b.comments?.length || 0) - (a.comments?.length || 0));
    }

    setFilteredRecipes(filtered);
  };

  // Handle confirming the purchase of a recipe
  const handleConfirmBuy = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        alert('Пожалуйста, войдите в систему.');
        return;
      }

      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);
      const userData = userDocSnap.data();

      if (userData.balance < selectedRecipeToBuy.price) {
        alert('Недостаточно Cokens для покупки.');
        return;
      }

      await updateUserBalance(user.uid, -selectedRecipeToBuy.price);
      await updateUserBalance(selectedRecipeToBuy.authorId, selectedRecipeToBuy.price);

      const updatedPurchasedRecipes = [...purchasedRecipes, selectedRecipeToBuy.id];
      setPurchasedRecipes(updatedPurchasedRecipes);

      await updateDoc(userDocRef, {
        purchasedRecipes: updatedPurchasedRecipes
      });

      await handleSaveRecipe(selectedRecipeToBuy);
      setIsBuyModalOpen(false);
    } catch (error) {
      console.error('Ошибка при покупке рецепта:', error);
      alert('Произошла ошибка при покупке рецепта');
    }
  };

  // Handle deleting a recipe (admin only)
  const handleDeleteRecipe = async (recipeId) => {
    try {
      await deleteSharedRecipe(recipeId);
      alert("Рецепт успешно удален");
    } catch (error) {
      console.error("Ошибка при удалении рецепта:", error);
      alert("У вас нет прав для удаления этого рецепта или произошла ошибка");
    }
  };

  // Handle submitting a new comment
  const handleCommentSubmit = (newComment) => {
    setSelectedRecipe((prevRecipe) => ({
      ...prevRecipe,
      comments: [...(prevRecipe.comments || []), newComment],
    }));
  };

  return (
    <div className="shared-recipes-container">
      <FilterBar onFilter={setFilters} />

      <div className="shared-recipes-grid">
        {filteredRecipes.map((recipe) => (
          <div className="shared-recipe-card" key={recipe.id}>
            <div className="shared-recipe-header" onClick={() => setSelectedRecipe(recipe)}>
              <div className="shared-author-info">
                <img
                  src={recipe.authorAvatar || '/default-avatar.jpg'}
                  alt={recipe.authorName}
                  className="shared-author-avatar"
                />
                <div className="shared-author-name">{recipe.authorName}</div>
              </div>
              <img
                src={recipe.slides && recipe.slides[0] ? recipe.slides[0] : '/default-image.jpg'}
                alt={recipe.name}
                className="shared-recipe-image"
              />
              <div className="shared-comments-count">
                <FontAwesomeIcon icon={faComments} /> {recipe.comments?.length || 0}
              </div>
            </div>
            <div className="shared-recipe-content">
              <h2 className="shared-recipe-name">{recipe.name}</h2>
              <div className="shared-recipe-description">{recipe.description}</div>
              <div className="shared-recipe-details">
                <div className="shared-recipe-detail"><span>Вес:</span> {recipe.totalWeight} гр</div>
                <div className="shared-recipe-detail"><span>Цена:</span> {recipe.totalCost} р</div>
                <div className="shared-recipe-detail"><span>Кк:</span> {recipe.totalCalories}</div>
              </div>
              <div className="shared-recipe-actions">
                {recipe.price > 0 ? (
                  purchasedRecipes.includes(recipe.id) ? (
                    <>
                      <button onClick={() => handleView(recipe.id)} className="shared-action-button">
                        <FontAwesomeIcon icon={faEye} />
                      </button>
                      <button onClick={() => handleSaveRecipe(recipe)} className="shared-action-button">
                        Куплено
                      </button>
                    </>
                  ) : (
                    <>
                      <button disabled className="shared-action-button">
                        <FontAwesomeIcon icon={faEye} />
                      </button>
                      <button onClick={() => handleBuyRecipe(recipe)} className="shared-save-recipe-button">
                        {recipe.price} Tokens
                      </button>
                    </>
                  )
                ) : (
                  <>
                    <button onClick={() => handleView(recipe.id)} className="shared-action-button">
                      <FontAwesomeIcon icon={faEye} />
                    </button>
                    <button onClick={() => handleSaveRecipe(recipe)} className="shared-action-button">
                      Сохранить
                    </button>
                  </>
                )}
                <button onClick={() => handleLike(recipe.id)} className="shared-action-button">
                  <FontAwesomeIcon icon={faThumbsUp} /> {recipe.likes || 0}
                </button>
                <button onClick={() => handleDislike(recipe.id)} className="shared-action-button">
                  <FontAwesomeIcon icon={faThumbsDown} /> {recipe.dislikes || 0}
                </button>
                {currentUser?.role === 'admin' && (
                  <button onClick={() => handleDeleteRecipe(recipe.id)} className="shared-delete-recipe-button">
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {isUserLoaded && selectedRecipe && (
        <RecipeModal
          recipe={selectedRecipe}
          onClose={() => setSelectedRecipe(null)}
          onCommentSubmit={handleCommentSubmit}
          currentUser={currentUser}
        />
      )}

      {isBuyModalOpen && selectedRecipeToBuy && (
        <div className="shared-modal-overlay">
          <div className="shared-modal-content">
            <h2>Подтвердите покупку</h2>
            <p>Вы хотите купить рецепт "{selectedRecipeToBuy.name}" за {selectedRecipeToBuy.price} Tokens?</p>
            <button onClick={handleConfirmBuy} className="shared-modal-button">
              Купить
            </button>
            <button onClick={() => setIsBuyModalOpen(false)} className="shared-modal-button">
              Отмена
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SharedRecipesList;
