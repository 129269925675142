// src/utils/videoUploader.js
import axios from 'axios';

/**
 * Функция для загрузки видео на Cloudinary с отслеживанием прогресса и возможностью отмены.
 * @param {File} file - Видео файл для загрузки.
 * @param {Function} onUploadProgress - Callback для отслеживания прогресса загрузки.
 * @param {CancelToken} cancelToken - Токен для отмены загрузки.
 * @returns {Promise<string>} - Возвращает URL загруженного видео.
 */
export const uploadToCloudinary = async (file, onUploadProgress, cancelToken) => {
  const CLOUDINARY_UPLOAD_URL = process.env.REACT_APP_CLOUDINARY_UPLOAD_URL;
  const UPLOAD_PRESET = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;

  // Временные логи для отладки (уберите их в продакшн)
  console.log('CLOUDINARY_UPLOAD_URL:', CLOUDINARY_UPLOAD_URL);
  console.log('UPLOAD_PRESET:', UPLOAD_PRESET);

  if (!CLOUDINARY_UPLOAD_URL || !UPLOAD_PRESET) {
    throw new Error('Cloudinary upload URL или upload preset не заданы в переменных окружения.');
  }

  const formData = new FormData();
  formData.append('file', file);
  formData.append('upload_preset', UPLOAD_PRESET);
  formData.append('resource_type', 'video'); // Установка типа ресурса

  try {
    const response = await axios.post(CLOUDINARY_UPLOAD_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
      cancelToken: cancelToken.token,
    });

    if (response.data.secure_url) {
      return response.data.secure_url;
    } else {
      throw new Error('Не удалось загрузить видео: ' + JSON.stringify(response.data));
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.warn('Загрузка видео отменена:', error.message);
    } else {
      console.error('Ошибка при загрузке видео на Cloudinary:', error);
      throw error;
    }
  }
};
