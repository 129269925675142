// RecipeModal.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import './RecipeModal.css';
import { db } from '../firebase';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';

const RecipeModal = ({ recipe, onClose, onCommentSubmit, currentUser }) => {
  const [comment, setComment] = useState('');
  const [commentError, setCommentError] = useState(false);

  if (!recipe) {
    return null;
  }

  const safeRecipe = {
    name: "Название рецепта",
    slides: ['/default-image.jpg'],
    authorName: "Автор неизвестен",
    totalWeight: 0,
    totalCost: 0,
    comments: [],
    ...recipe,
  };

  const handleCommentSubmit = async () => {
    const wordCount = comment.split(' ').length;

    if (wordCount < 5 || wordCount > 40) {
      setCommentError(true);
      return;
    }

    setCommentError(false);
    const newComment = {
      id: Date.now(), 
      text: comment,
      authorName: currentUser ? currentUser.name : "Гость",
      authorAvatar: currentUser ? currentUser.avatar : "/default-avatar.jpg",
      timestamp: new Date().toISOString(),
      likes: 0,
      dislikes: 0,
    };

    try {
      const recipeRef = doc(db, 'sharedRecipes', safeRecipe.id);
      await updateDoc(recipeRef, {
        comments: arrayUnion(newComment)
      });
      onCommentSubmit(newComment);
      setComment('');
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faClose} />
        </button>
        <h2>{safeRecipe.name}</h2>
        <img src={safeRecipe.slides[0]} alt={safeRecipe.name} className="modal-recipe-image" />
        <div className="recipe-details">
          <p style={{ color: 'gold' }}>Автор: {safeRecipe.authorName}</p>
          <p>Место: {safeRecipe.authorPosition}</p>
          <p>Вес: {safeRecipe.totalWeight} гр</p>
          <p>Стоимость: {safeRecipe.totalCost} руб.</p>
        </div>
        <div className="comments-section">
          <h3>Комментарии ({safeRecipe.comments?.length})</h3>
          {safeRecipe.comments.map((comment) => (
            <div key={comment.id} className="comment">
              <img src={comment.authorAvatar} alt={comment.authorName} className="comment-avatar" />
              <div>
                <p>{comment.authorName}</p>
                <p>{comment.text}</p>
              </div>
            </div>
          ))}
          <div className="add-comment">
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className={commentError ? 'comment-input-error' : ''}
              placeholder="Добавьте комментарий (от 5 до 40 слов)"
            />
            {commentError && (
              <p className="error-message">Комментарий должен содержать от 5 до 40 слов.</p>
            )}
            <button onClick={handleCommentSubmit}>Отправить</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeModal;